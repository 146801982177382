import $store from "@/store";
const Setting = {
  rules: {
    tips: {
      zh: "温馨提示:",
      en: "Reminder:"
    },
    rule1: {
      zh: "1、疫情防控期间，进入景区需佩戴口罩。同时请您噢诶个景区做好入园体温检测以及实名登记工作。由此给您带来不便敬请谅解。",
      en: "1. During the epidemic prevention and control period, a mask must be worn when entering the scenic spot. At the same time, please do a good job in entering the kindergarten body temperature detection and real-name registration in this scenic spot. We apologize for the inconvenience caused."
    },
    rule2: {
      zh: "1、一人一票，当日入园有效。",
      en: "1. One person, one ticket, admission is valid on the day."
    },
    rule3: {
      zh: "2、享受免票及优惠票政策游客需出示有关证件入园。",
      en: "2. Tourists who enjoy the free and preferential ticket policies are required to present relevant certificates to enter the park."
    }
  },
  openTime: {
    openTime: {
      zh: "开放时间",
      en: "OpenTime"
    },
    footerInfo: {
      zh: "以上信息仅供参考，具体以景区当日实际公示信息为准",
      en: "The above information is for reference only, and the specific information is subject to the actual public information on the day of the scenic spot"
    },
    summer: {
      zh: "夏令",
      en: "Summer"
    },
    winter: {
      zh: "冬令",
      en: "Winter"
    },
    time: {
      zh: "周一至周日",
      en: "Whole week"
    },
    dayTime: {
      zh: "全天",
      en: "Whole day"
    },
    detailedtime: {
      zh: "09:00-18:00,18:00停止入场",
      en: "09:00-18:00,18:00 Close"
    }
  },
  treatment: {
    treatmentLabel: {
      zh: "门票优待政策",
      en: "Preferential treatment policy"
    },
    treatment: {
      zh: "优待政策",
      en: "Treatment policy"
    },
    policy: {
      zh: [{
        discount: "免费",
        audience: "老年人",
        info: "65周岁（含）以上凭老年证或身份证原件",
      }, {
        discount: "免费",
        audience: "儿童",
        info: "身高1.4米（含）以下及6周岁（含）以下凭相关有效证件",
      }, {
        discount: "免费",
        audience: "市级以上劳动模范",
        info: "凭获得市级以上劳模荣誉奖项或证书",
      }, {
        discount: "免费",
        audience: "无偿献血人员",
        info: "凭三星级以上献血荣誉卡原件/电子献血卡",
      }, {
        discount: "免费",
        audience: "残疾人",
        info: "凭残疾证",
      }, {
        discount: "免费",
        audience: "现役军人",
        info: "凭军官证原件(中华人民共和国现役军人包括武警)",
      }, {
        discount: "优惠",
        audience: "全日制大中小学生",
        info: "全日制大学本科及 以下学历学生(含港澳台等入境游学生，不含成人教育、自考类及研究生)需出示学生证原件/学信网在籍在读证明",
      }, {
        discount: "5.0折",
        audience: "老年人",
        info: "60周岁（含）-65周岁（不含）凭老年证或身份证原件",
      }],
      en: [{
        discount: "Free",
        audience: "The elderly",
        info: "65 years old (inclusive) and older with old-age certificate or ID card",
      }, {
        discount: "Free",
        audience: "Child",
        info: "Under 1.4 meters (inclusive) and under 6 years old (inclusive) with relevant valid documents",
      }, {
        discount: "Free",
        audience: "Model workers above the municipal level",
        info: "With the honor award or certificate of model worker at or above the city level",
      }, {
        discount: "Free",
        audience: "Unpaid blood donors",
        info: "Original/electronic blood donation card with a three-star or above blood donation honor card",
      }, {
        discount: "Free",
        audience: "Handicapped",
        info: "With a disability certificate",
      }, {
        discount: "Free",
        audience: "Active military",
        info: "Original military officer certificate (including People's Republic of China active duty soldiers and armed police)",
      }, {
        discount: "Discount",
        audience: "Full-time college students",
        info: "Full time undergraduate and below degree students (including inbound tourists from Hong Kong, Macao, and Taiwan, excluding adult education, self-study, and graduate students) are required to present their original student ID card/Certificate of Enrollment on China Academic Degrees and Information Technology Network",
      }, {
        discount: "50% off",
        audience: "The elderly",
        info: "60 years old (inclusive)-65 years old (not included) with old-age certificate or ID card",
      }]
    },
  },
  policy: {
    marketPrice: {
      zh: {
        title: '门市价：',
        price: '45元/人/次'
      },
      en: {
        title: 'Retail Price：',
        price: '45 yuan/person/time'
      },
    },
    cards: {
      zh: [{
        title: '学生票',
        subtitle: '',
        cost: '20元/人/次'
      }, {
        title: '线上售价',
        subtitle: '微信公众号等',
        cost: '40元/人/次'
      }, {
        title: '晨练卡',
        subtitle: '仅限当日7-9时入园',
        cost: '20元/月'
      }, {
        title: '月卡',
        subtitle: '',
        cost: '60元/月'
      }, {
        title: '年卡',
        subtitle: '',
        cost: '300元/年'
      }, {
        title: '优惠票价格',
        subtitle: '',
        cost: '20元/人'
      }, ],
      en: [{
        title: 'Student ticket',
        subtitle: '',
        cost: '20 yuan/person/time'
      }, {
        title: 'Online ticket',
        subtitle: 'WeChat public account, etc.',
        cost: '40 yuan/person/time'
      }, {
        title: 'Morning Exercise Card',
        subtitle: 'Entry only from 7-9 on the day',
        cost: '20 yuan/month'
      }, {
        title: 'Monthly card',
        subtitle: '',
        cost: '60 yuan/month'
      }, {
        title: 'Year card',
        subtitle: '',
        cost: '300 yuan/year'
      }, {
        title: 'Discount ticket price',
        subtitle: '',
        cost: '20 yuan/person'
      }, ]
    },
    forPople: {
      zh: {
        title: '适用人群：',
        rules: [
          '1）6周岁（不含6周岁）～18周岁（含18周岁）未成年人；',
          '2）全日制大学本科及以下学历学生(含港澳台等入境游学生，不含成人教育、自考类及研究生)；',
          '3）60周岁（含60周岁）至65周岁（不含65周岁）以下的老年人。',
        ]
      },
      en: {
        title: 'For people：',
        rules: [
          '1）Minors from 6 years old (excluding 6 years old) to 18 years old (including 18 years old);',
          '2）Full-time university students with a bachelor’s degree or below (including inbound students from Hong Kong, Macau and Taiwan, excluding adult education, self-examination and graduate students);',
          '3）Elderly people from 60 years old (including 60 years old) to 65 years old (excluding 65 years old).',
        ]
      },
    },
    FreeTicket: {
      zh: {
        title: '免票人群：',
        rules: [
          '1）6周岁（含6周岁）以下或身高1.4米（含1.4米）以下的儿童、65周岁(含65周岁)及以上老年人、现役军人(中华人民共和国现役军人，包括武警)、残障人士；',
          '2）“三星级献血之星”奖项及以上的获奖者、“五星级无偿献血志愿者”奖项的获奖者、无偿献血志愿服务终身荣誉奖奖项的获得者和无偿献血终身成就奖奖项获得者；',
          '3）获得市级以上劳模荣誉奖项或证书的人员。',
        ]
      },
      en: {
        title: 'Free ticket：',
        rules: [
          "1）Children under 6 years old (including 6 years old) or height of 1.4 meters (including 1.4 meters), elderly people 65 years old (including 65 years old) and above, active military personnel (active military personnel of the People's Republic of China, including armed police), and disabled persons;",
          '2）Winners of the "Three-star Star of Blood Donation" award and above, winners of the "Five-star Volunteer Blood Donation" award, winners of the Lifetime Honor Award for Voluntary Blood Donation Service, and winners of the Lifetime Achievement Award for Voluntary Blood Donation;',
          '3）Personnel who have won honorary awards or certificates for model workers above the city level.',
        ]
      },
    },
  },

}

function getSettingLang() {
  return $store.getters.getLang
}

function getSetting(type) {
  const settingLang = getSettingLang()
  let setting = {}
  for (var key in Setting[type]) {
    setting[key] = Setting[type][key][settingLang]
  }
  return setting
}

function getAllTicketSetting() {
  return {
    RulesSetting: getSetting('rules'),
    OpenTimeSetting: getSetting('openTime'),
    TreatmentSetting: getSetting('treatment'),
    PolicySetting: getSetting('policy'),
  }
}

export {
  getAllTicketSetting,
};