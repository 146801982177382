/* eslint-disable */
export default [
  /**
   * ==================================================
   *  公共分类
   *  公共分类
   * ==================================================
   */

  // 手机登录接口
  // doc: http://192.168.2.18:3000/project/484/interface/api/63769
  {
    funcName: "api_login",
    method: "POST",
    url: "/v1/api/login",
  },

  // token刷新
  // doc: http://192.168.2.18:3000/project/484/interface/api/63773
  {
    funcName: "api_refresh_token",
    method: "POST",
    url: "/v1/api/refresh_token",
  },

  // token刷新接口
  // doc: http://192.168.2.18:3000/project/484/interface/api/63777
  {
    funcName: "api_refresh_token",
    method: "GET",
    url: "/v1/api/refresh_token",
  },

  // 三方账号绑定本地账号接口
  // doc: http://192.168.2.18:3000/project/484/interface/api/63781
  {
    funcName: "api_bind_account",
    method: "POST",
    url: "/v1/api/bind_account",
  },

  // 三方账号绑定用户接口
  // doc: http://192.168.2.18:3000/project/484/interface/api/63785
  {
    funcName: "api_bind_account_to_uid",
    method: "POST",
    url: "/v1/api/bind_account_to_uid",
  },

  // 客户端日志上传
  // doc: http://192.168.2.18:3000/project/484/interface/api/63793
  {
    funcName: "api_log_log",
    method: "POST",
    url: "/v1/api/log/log",
  },

  // 微信、支付宝等三方账号授权登录接口
  // doc: http://192.168.2.18:3000/project/484/interface/api/63805
  {
    funcName: "api_login3rd",
    method: "POST",
    url: "/v1/api/login3rd",
  },

  // 解密微信手机号
  // doc: http://192.168.2.18:3000/project/484/interface/api/63833
  {
    funcName: "api_exchange_wechat_phone",
    method: "POST",
    url: "/v1/api/exchange_wechat_phone",
  },

  // 退出登陆
  // doc: http://192.168.2.18:3000/project/484/interface/api/63837
  {
    funcName: "api_logout",
    method: "POST",
    url: "/v1/api/logout",
  },

  // 验证码接口
  // doc: http://192.168.2.18:3000/project/484/interface/api/63841
  {
    funcName: "api_send_verify_code",
    method: "POST",
    url: "/v1/api/send_verify_code",
  },

  /**
   * ==================================================
   *  公共分类
   *  公共分类
   * ==================================================
   */

  // 取消点赞
  // doc: http://192.168.2.18:3000/project/484/interface/api/63789
  {
    funcName: "Common_antiLike",
    method: "POST",
    url: "/Api/Common/antiLike",
  },

  // 点赞
  // doc: http://192.168.2.18:3000/project/484/interface/api/63813
  {
    funcName: "Common_like",
    method: "POST",
    url: "/Api/Common/like",
  },

  // 验证码图片
  // doc: http://192.168.2.18:3000/project/484/interface/api/65589
  {
    funcName: "Common_verifyCode",
    method: "GET",
    url: "/v1/Common/verifyCode",
  },

  /**
   * ==================================================
   *  公共分类
   *  公共分类
   * ==================================================
   */

  // 小程序错误打印
  // doc: http://192.168.2.18:3000/project/484/interface/api/63797
  {
    funcName: "wechat_error_log",
    method: "POST",
    url: "/v1/wechat/error/log",
  },

  // 获取jsapi临时票据
  // doc: http://192.168.2.18:3000/project/484/interface/api/63821
  {
    funcName: "wechat_ticket",
    method: "POST",
    url: "/v1/wechat/ticket",
  },

  // 获取小程序推送模板id
  // doc: http://192.168.2.18:3000/project/484/interface/api/63829
  {
    funcName: "wechat_submsg_temps",
    method: "GET",
    url: "/v1/wechat/submsg/temps",
  },

  /**
   * ==================================================
   *  公共分类
   *  公共分类
   * ==================================================
   */

  // 文件上传
  // doc: http://192.168.2.18:3000/project/484/interface/api/63809
  {
    funcName: "Api_upload",
    method: "POST",
    url: "/v1/Api/upload",
  },

  /**
   * ==================================================
   *  公共分类
   *  公共分类
   * ==================================================
   */

  // 系统时间获取
  // doc: http://192.168.2.18:3000/project/484/interface/api/63817
  {
    funcName: "sysTime",
    method: "GET",
    url: "/v1/sysTime",
  },

  /**
   * ==================================================
   *  公共分类
   *  公共分类
   * ==================================================
   */

  // 获取外链
  // doc: http://192.168.2.18:3000/project/484/interface/api/63825
  {
    funcName: "extraLink",
    method: "GET",
    url: "/v1/extraLink",
  },

  /**
   * ==================================================
   *  公共分类
   *  公共分类
   * ==================================================
   */

  // 综合搜索
  // doc: http://192.168.2.18:3000/project/484/interface/api/64137
  {
    funcName: "globalSearch",
    method: "GET",
    url: "/v1/globalSearch",
  },

  /**
   * ==================================================
   *  公共分类
   *  公共分类
   * ==================================================
   */

  // 天气
  // doc: http://192.168.2.18:3000/project/484/interface/api/64505
  {
    funcName: "weather_query",
    method: "GET",
    url: "/v1/weather/query",
  },

  /**
   * ==================================================
   *  公共分类
   *  公共分类
   * ==================================================
   */

  // 前端页面首页数据
  // doc: http://192.168.2.18:3000/project/484/interface/api/64517
  {
    funcName: "home_home",
    method: "GET",
    url: "/v1/home/home",
  },

  /**
   * ==================================================
   *  公共分类
   *  公共分类
   * ==================================================
   */

  // 地图导览
  // doc: http://192.168.2.18:3000/project/484/interface/api/67993
  {
    funcName: "mapShow",
    method: "GET",
    url: "/v1/mapShow",
  },

  /**
   * ==================================================
   *  用户
   *  null
   * ==================================================
   */

  // 用户签到
  // doc: http://192.168.2.18:3000/project/484/interface/api/63845
  {
    funcName: "user_signin",
    method: "POST",
    url: "/v1/user/signin",
  },

  // 实名信息
  // doc: http://192.168.2.18:3000/project/484/interface/api/63849
  {
    funcName: "user_verify_info",
    method: "GET",
    url: "/v1/user/verify_info",
  },

  // 实名/驾驶证认证
  // doc: http://192.168.2.18:3000/project/484/interface/api/63853
  {
    funcName: "user_real_verify",
    method: "POST",
    url: "/v1/user/real_verify",
  },

  // 我的优惠券
  // doc: http://192.168.2.18:3000/project/484/interface/api/63857
  {
    funcName: "user_coupon_list",
    method: "GET",
    url: "/v1/user/coupon/list",
  },

  // 手机号绑定
  // doc: http://192.168.2.18:3000/project/484/interface/api/63861
  {
    funcName: "user_bind_phone",
    method: "POST",
    url: "/v1/user/bind_phone",
  },

  // 文章支付中间页
  // doc: http://192.168.2.18:3000/project/484/interface/api/63865
  {
    funcName: "user_article_breakPay",
    method: "POST",
    url: "/v1/user/article/breakPay",
  },

  // 更新-用户信息
  // doc: http://192.168.2.18:3000/project/484/interface/api/63869
  {
    funcName: "user_update",
    method: "POST",
    url: "/v1/user/update",
  },

  // 获取-用户信息详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/63881
  {
    funcName: "user_info",
    method: "GET",
    url: "/v1/user/info",
  },

  // 获取-用户积分日志
  // doc: http://192.168.2.18:3000/project/484/interface/api/63885
  {
    funcName: "user_scoreLog_list",
    method: "GET",
    url: "/v1/user/scoreLog/list",
  },

  // 获取手机号绑定码
  // doc: http://192.168.2.18:3000/project/484/interface/api/63889
  {
    funcName: "user_get_bind_code",
    method: "POST",
    url: "/v1/user/get_bind_code",
  },

  // 获取用户已点单列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/63893
  {
    funcName: "user_booked_programs",
    method: "GET",
    url: "/v1/user/booked/programs",
  },

  // 获取用户已预定的场地详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/63897
  {
    funcName: "user_order_articleDetails",
    method: "GET",
    url: "/v1/user/order/articleDetails",
  },

  // 获取用户已预定的活动详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/63901
  {
    funcName: "user_order_activityDetails",
    method: "GET",
    url: "/v1/user/order/activityDetails",
  },

  // 获取用户已预约场地
  // doc: http://192.168.2.18:3000/project/484/interface/api/63905
  {
    funcName: "user_booked_articles",
    method: "GET",
    url: "/v1/user/booked/articles",
  },

  // 获取用户已预约活动
  // doc: http://192.168.2.18:3000/project/484/interface/api/63909
  {
    funcName: "user_booked_activities",
    method: "GET",
    url: "/v1/user/booked/activities",
  },

  // 联系人列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/63917
  {
    funcName: "user_contact_list",
    method: "GET",
    url: "/v1/user/contact/list",
  },

  // 联系人详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/63921
  {
    funcName: "user_contact_detail",
    method: "GET",
    url: "/v1/user/contact/detail",
  },

  // 新增联系人
  // doc: http://192.168.2.18:3000/project/484/interface/api/63925
  {
    funcName: "user_contact_add",
    method: "POST",
    url: "/v1/user/contact/add",
  },

  // 编辑联系人
  // doc: http://192.168.2.18:3000/project/484/interface/api/63929
  {
    funcName: "user_contact_edit",
    method: "POST",
    url: "/v1/user/contact/edit",
  },

  // 删除联系人
  // doc: http://192.168.2.18:3000/project/484/interface/api/63933
  {
    funcName: "user_contact_delete",
    method: "POST",
    url: "/v1/user/contact/delete",
  },

  // 设为默认联系人
  // doc: http://192.168.2.18:3000/project/484/interface/api/63937
  {
    funcName: "user_contact_default",
    method: "POST",
    url: "/v1/user/contact/default",
  },

  // 【获取】我的评论列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/63941
  {
    funcName: "user_comment_list",
    method: "GET",
    url: "/v1/user/comment/list",
  },

  // 积分商城
  // doc: http://192.168.2.18:3000/project/484/interface/api/64081
  {
    funcName: "user_scoreStore",
    method: "GET",
    url: "/v1/user/scoreStore",
  },

  // 借阅列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/64185
  {
    funcName: "user_userCard_interlibListOnLoan",
    method: "GET",
    url: "/v1/user/userCard/interlibListOnLoan",
  },

  // 卡包列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/64189
  {
    funcName: "user_userCard_cards",
    method: "GET",
    url: "/v1/user/userCard/cards",
  },

  // 卡详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/64193
  {
    funcName: "user_userCard_cardDetail",
    method: "GET",
    url: "/v1/user/userCard/cardDetail",
  },

  // 图书续借
  // doc: http://192.168.2.18:3000/project/484/interface/api/64197
  {
    funcName: "user_userCard_interlibDoRenew",
    method: "POST",
    url: "/v1/user/userCard/interlibDoRenew",
  },

  // 绑定读者证
  // doc: http://192.168.2.18:3000/project/484/interface/api/64201
  {
    funcName: "user_userCard_interlibBind",
    method: "POST",
    url: "/v1/user/userCard/interlibBind",
  },

  // 解除绑定读者证
  // doc: http://192.168.2.18:3000/project/484/interface/api/64205
  {
    funcName: "user_userCard_interlibUnbind",
    method: "POST",
    url: "/v1/user/userCard/interlibUnbind",
  },

  // 后台用户登录
  // doc: http://192.168.2.18:3000/project/484/interface/api/64213
  {
    funcName: "user_loginSystem",
    method: "POST",
    url: "/v1/user/loginSystem",
  },

  // 退出登录
  // doc: http://192.168.2.18:3000/project/484/interface/api/64225
  {
    funcName: "user_logout",
    method: "POST",
    url: "/v1/user/logout",
  },

  // 认证结果
  // doc: http://192.168.2.18:3000/project/484/interface/api/64329
  {
    funcName: "user_auth_check",
    method: "POST",
    url: "/v1/user/auth_check",
  },

  /**
   * ==================================================
   *  用户
   *  null
   * ==================================================
   */

  // 用户第三方解绑
  // doc: http://192.168.2.18:3000/project/484/interface/api/63873
  {
    funcName: "unbind3rd",
    method: "GET",
    url: "/api/unbind3rd",
  },

  /**
   * ==================================================
   *  用户
   *  null
   * ==================================================
   */

  // 获取-收货地址列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/63877
  {
    funcName: "shop_address_list?copy",
    method: "GET",
    url: "/v1/shop/address_list?copy",
  },

  // 获取取货二维码
  // doc: http://192.168.2.18:3000/project/484/interface/api/63965
  {
    funcName: "shop_qrcode",
    method: "GET",
    url: "/v1/shop/qrcode",
  },

  // 产品分类
  // doc: http://192.168.2.18:3000/project/484/interface/api/63969
  {
    funcName: "shop_goods_category",
    method: "GET",
    url: "/v1/shop/goods/category",
  },

  // 产品搜索
  // doc: http://192.168.2.18:3000/project/484/interface/api/64021
  {
    funcName: "shop_goods_search",
    method: "GET",
    url: "/v1/shop/goods/search",
  },

  // 产品详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/63973
  {
    funcName: "shop_goods_detail",
    method: "GET",
    url: "/v1/shop/goods_detail",
  },

  // 修改地址
  // doc: http://192.168.2.18:3000/project/484/interface/api/63977
  {
    funcName: "shop_update_address",
    method: "POST",
    url: "/v1/shop/update_address",
  },

  // 删除地址
  // doc: http://192.168.2.18:3000/project/484/interface/api/63981
  {
    funcName: "shop_delete_address",
    method: "POST",
    url: "/v1/shop/delete_address",
  },

  // 售后商品信息
  // doc: http://192.168.2.18:3000/project/484/interface/api/63985
  {
    funcName: "shop_aftersale_goods_info",
    method: "GET",
    url: "/v1/shop/aftersale_goods_info",
  },

  // 售后详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/63989
  {
    funcName: "shop_aftersale_detail",
    method: "GET",
    url: "/v1/shop/aftersale_detail",
  },

  // 已申请售后列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/63993
  {
    funcName: "shop_aftersale_list",
    method: "GET",
    url: "/v1/shop/aftersale_list",
  },

  // 待评论接口
  // doc: http://192.168.2.18:3000/project/484/interface/api/63997
  {
    funcName: "shop_pending_comment_list",
    method: "POST",
    url: "/v1/shop/pending_comment_list",
  },

  // 提交订单
  // doc: http://192.168.2.18:3000/project/484/interface/api/64001
  {
    funcName: "shop_do_order?copy=shop",
    method: "POST",
    url: "/v1/shop/do_order?copy=shop",
  },

  // 添加地址
  // doc: http://192.168.2.18:3000/project/484/interface/api/64009
  {
    funcName: "shop_add_address",
    method: "POST",
    url: "/v1/shop/add_address",
  },

  // 申请售后
  // doc: http://192.168.2.18:3000/project/484/interface/api/64013
  {
    funcName: "shop_order_refund",
    method: "POST",
    url: "/v1/shop/order_refund",
  },

  // 确认收货
  // doc: http://192.168.2.18:3000/project/484/interface/api/64017
  {
    funcName: "shop_do_receive",
    method: "POST",
    url: "/v1/shop/do_receive",
  },

  // 获取地址详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/64025
  {
    funcName: "shop_address_info",
    method: "GET",
    url: "/v1/shop/address_info",
  },

  // 获取收货地址列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/64029
  {
    funcName: "shop_address_list",
    method: "GET",
    url: "/v1/shop/address_list",
  },

  // 获取自提地址
  // doc: http://192.168.2.18:3000/project/484/interface/api/64033
  {
    funcName: "shop_self_address",
    method: "GET",
    url: "/v1/shop/self_address",
  },

  // 获取订单商品详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/64037
  {
    funcName: "shop_item_detail",
    method: "GET",
    url: "/v1/shop/item_detail",
  },

  // 订单确认
  // doc: http://192.168.2.18:3000/project/484/interface/api/64041
  {
    funcName: "shop_confirm_order?copy=shop",
    method: "POST",
    url: "/v1/shop/confirm_order?copy=shop",
  },

  // 设置为默认地址
  // doc: http://192.168.2.18:3000/project/484/interface/api/64045
  {
    funcName: "shop_set_default",
    method: "POST",
    url: "/v1/shop/set_default",
  },

  // 购物车产品删除
  // doc: http://192.168.2.18:3000/project/484/interface/api/64049
  {
    funcName: "shop_cart_delete",
    method: "POST",
    url: "/v1/shop/cart_delete",
  },

  // 购物车产品数量更新
  // doc: http://192.168.2.18:3000/project/484/interface/api/64053
  {
    funcName: "shop_cart_update",
    method: "POST",
    url: "/v1/shop/cart_update",
  },

  // 购物车产品添加
  // doc: http://192.168.2.18:3000/project/484/interface/api/64057
  {
    funcName: "shop_cart_add",
    method: "POST",
    url: "/v1/shop/cart_add",
  },

  // 购物车全选
  // doc: http://192.168.2.18:3000/project/484/interface/api/64061
  {
    funcName: "shop_cart_check_all",
    method: "POST",
    url: "/v1/shop/cart_check_all",
  },

  // 购物车列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/64065
  {
    funcName: "shop_cart_list",
    method: "GET",
    url: "/v1/shop/cart_list",
  },

  // 购物车商品数量
  // doc: http://192.168.2.18:3000/project/484/interface/api/64069
  {
    funcName: "shop_get_cart_goods_num",
    method: "GET",
    url: "/v1/shop/get_cart_goods_num",
  },

  // 购物车选中
  // doc: http://192.168.2.18:3000/project/484/interface/api/64073
  {
    funcName: "shop_cart_check",
    method: "POST",
    url: "/v1/shop/cart_check",
  },

  // 待核销列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/64217
  {
    funcName: "shop_order_verify_list",
    method: "GET",
    url: "/v1/shop/order/verify_list",
  },

  // 核销
  // doc: http://192.168.2.18:3000/project/484/interface/api/64221
  {
    funcName: "shop_destroy_qrcode",
    method: "POST",
    url: "/v1/shop/destroy/qrcode",
  },

  // 取消订单
  // doc: http://192.168.2.18:3000/project/484/interface/api/64229
  {
    funcName: "shop_do_cancel",
    method: "POST",
    url: "/v1/shop/do_cancel",
  },

  // 提交订单
  // doc: http://192.168.2.18:3000/project/484/interface/api/64233
  {
    funcName: "shop_do_order",
    method: "POST",
    url: "/v1/shop/do_order",
  },

  // 支付
  // doc: http://192.168.2.18:3000/project/484/interface/api/64237
  {
    funcName: "shop_do_pay",
    method: "POST",
    url: "/v1/shop/do_pay",
  },

  // 订单列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/64241
  {
    funcName: "shop_orders",
    method: "GET",
    url: "/v1/shop/orders",
  },

  // 订单确认
  // doc: http://192.168.2.18:3000/project/484/interface/api/64245
  {
    funcName: "shop_confirm_order",
    method: "POST",
    url: "/v1/shop/confirm_order",
  },

  // 订单详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/64249
  {
    funcName: "shop_order_detail",
    method: "GET",
    url: "/v1/shop/order_detail",
  },

  // 提交订单
  // doc: http://192.168.2.18:3000/project/484/interface/api/64257
  {
    funcName: "shop_do_order?copy=ticket",
    method: "POST",
    url: "/v1/shop/do_order?copy=ticket",
  },

  // 订单确认
  // doc: http://192.168.2.18:3000/project/484/interface/api/64265
  {
    funcName: "shop_confirm_order?copy=ticket",
    method: "POST",
    url: "/v1/shop/confirm_order?copy=ticket",
  },

  // 订单详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/64269
  {
    funcName: "shop_order_detail?copy=ticket",
    method: "GET",
    url: "/v1/shop/order_detail?copy=ticket",
  },

  // 提交订单
  // doc: http://192.168.2.18:3000/project/484/interface/api/64305
  {
    funcName: "shop_do_order?copy=scooter",
    method: "POST",
    url: "/v1/shop/do_order?copy=scooter",
  },

  // 支付押金
  // doc: http://192.168.2.18:3000/project/484/interface/api/64309
  {
    funcName: "shop_do_pay?copy=scooter",
    method: "POST",
    url: "/v1/shop/do_pay?copy=scooter",
  },

  // 订单列表代步车订单详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/64321
  {
    funcName: "shop_order_detail?copy=scooter",
    method: "GET",
    url: "/v1/shop/order_detail?copy=scooter",
  },

  // 订单确认
  // doc: http://192.168.2.18:3000/project/484/interface/api/64325
  {
    funcName: "shop_confirm_order?copy=scooter_1610093118904",
    method: "POST",
    url: "/v1/shop/confirm_order?copy=scooter_1610093118904",
  },

  /**
   * ==================================================
   *  用户
   *  null
   * ==================================================
   */

  // 获取第三方绑定平台列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/63913
  {
    funcName: "list3rdPlatforms",
    method: "GET",
    url: "/api/list3rdPlatforms",
  },

  /**
   * ==================================================
   *  评论
   *  null
   * ==================================================
   */

  // 添加-评论
  // doc: http://192.168.2.18:3000/project/484/interface/api/63945
  {
    funcName: "comment_create",
    method: "POST",
    url: "/v1/comment/create",
  },

  // 获取-评论列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/63949
  {
    funcName: "comment_list",
    method: "GET",
    url: "/v1/comment/list",
  },

  /**
   * ==================================================
   *  收藏
   *  null
   * ==================================================
   */

  // 删除-收藏（取消收藏）
  // doc: http://192.168.2.18:3000/project/484/interface/api/63953
  {
    funcName: "collection_cancel",
    method: "POST",
    url: "/v1/collection/cancel",
  },

  // 添加-收藏
  // doc: http://192.168.2.18:3000/project/484/interface/api/63957
  {
    funcName: "collection_create",
    method: "POST",
    url: "/v1/collection/create",
  },

  // 获取-收藏列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/63961
  {
    funcName: "collection_list",
    method: "GET",
    url: "/v1/collection/list",
  },

  /**
   * ==================================================
   *  文旅超市
   *  null
   * ==================================================
   */

  // 支付成功回调模拟
  // doc: http://192.168.2.18:3000/project/484/interface/api/64005
  {
    funcName: "",
    method: "POST",
    url: "/wx_pay_notify_test",
  },

  /**
   * ==================================================
   *  优惠卷
   *  null
   * ==================================================
   */

  // 积分兑换优惠卷
  // doc: http://192.168.2.18:3000/project/484/interface/api/64077
  {
    funcName: "coupon_exchange",
    method: "POST",
    url: "/v1/coupon/exchange",
  },

  // 获取我的优惠卷
  // doc: http://192.168.2.18:3000/project/484/interface/api/64085
  {
    funcName: "coupon_my_coupon",
    method: "GET",
    url: "/v1/coupon/my_coupon",
  },

  // 获取现有的优惠卷列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/64089
  {
    funcName: "coupon_list",
    method: "GET",
    url: "/v1/coupon/list",
  },

  /**
   * ==================================================
   *  广告
   *  null
   * ==================================================
   */

  // 获取广告内容
  // doc: http://192.168.2.18:3000/project/484/interface/api/64093
  {
    funcName: "ad_adInfo",
    method: "GET",
    url: "/v1/ad/adInfo",
  },

  /**
   * ==================================================
   *  投诉
   *  null
   * ==================================================
   */

  // 我的投诉
  // doc: http://192.168.2.18:3000/project/484/interface/api/64097
  {
    funcName: "complain_my_complain",
    method: "GET",
    url: "/v1/complain/my_complain",
  },

  // 投诉
  // doc: http://192.168.2.18:3000/project/484/interface/api/64101
  {
    funcName: "complain_complain",
    method: "POST",
    url: "/v1/complain/complain",
  },

  // 投诉详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/64105
  {
    funcName: "complain_detail",
    method: "GET",
    url: "/v1/complain/detail",
  },

  /**
   * ==================================================
   *  建议反馈
   *  建议反馈
   * ==================================================
   */

  // 我的建议列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/64109
  {
    funcName: "advise_list",
    method: "GET",
    url: "/v1/advise/list",
  },

  // 添加-建议反馈
  // doc: http://192.168.2.18:3000/project/484/interface/api/64113
  {
    funcName: "advise_create",
    method: "POST",
    url: "/v1/advise/create",
  },

  // 获取-建议反馈详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/64117
  {
    funcName: "advise_detail",
    method: "GET",
    url: "/v1/advise/detail",
  },

  // 获取——意见反馈分类
  // doc: http://192.168.2.18:3000/project/484/interface/api/64121
  {
    funcName: "advise_listCategory",
    method: "GET",
    url: "/v1/advise/listCategory",
  },

  // 获取—所有的意见反馈
  // doc: http://192.168.2.18:3000/project/484/interface/api/64125
  {
    funcName: "advise_listAll",
    method: "GET",
    url: "/v1/advise/listAll",
  },

  /**
   * ==================================================
   *  点赞、分享
   *  点赞分享接口
   * ==================================================
   */

  // 分享
  // doc: http://192.168.2.18:3000/project/484/interface/api/64129
  {
    funcName: "share_create",
    method: "POST",
    url: "/v1/share/create",
  },

  /**
   * ==================================================
   *  点赞、分享
   *  点赞分享接口
   * ==================================================
   */

  // 点赞/取消点赞（同个接口）
  // doc: http://192.168.2.18:3000/project/484/interface/api/64133
  {
    funcName: "like_toggle",
    method: "POST",
    url: "/v1/like/toggle",
  },

  /**
   * ==================================================
   *  版本管理
   *  null
   * ==================================================
   */

  // udpate
  // doc: http://192.168.2.18:3000/project/484/interface/api/64149
  {
    funcName: "version_update",
    method: "GET",
    url: "/v1/version/update",
  },

  /**
   * ==================================================
   *  线下券
   *  null
   * ==================================================
   */

  // 我的线下券列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/64153
  {
    funcName: "offlineCouponOrder_mine",
    method: "GET",
    url: "/v1/offlineCouponOrder/mine",
  },

  // 我的线下券详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/64157
  {
    funcName: "offlineCouponOrder_detail",
    method: "GET",
    url: "/v1/offlineCouponOrder/detail",
  },

  // 订单取消
  // doc: http://192.168.2.18:3000/project/484/interface/api/64169
  {
    funcName: "offlineCouponOrder_cancel",
    method: "POST",
    url: "/v1/offlineCouponOrder/cancel",
  },

  /**
   * ==================================================
   *  线下券
   *  null
   * ==================================================
   */

  // 线下券列表获取
  // doc: http://192.168.2.18:3000/project/484/interface/api/64161
  {
    funcName: "offlineCoupon_index",
    method: "GET",
    url: "/v1/offlineCoupon/index",
  },

  // 线下券详情获取
  // doc: http://192.168.2.18:3000/project/484/interface/api/64165
  {
    funcName: "offlineCoupon_detail",
    method: "GET",
    url: "/v1/offlineCoupon/detail",
  },

  // 订单提交
  // doc: http://192.168.2.18:3000/project/484/interface/api/64173
  {
    funcName: "offlineCoupon_commit",
    method: "POST",
    url: "/v1/offlineCoupon/commit",
  },

  // 订单支付
  // doc: http://192.168.2.18:3000/project/484/interface/api/64177
  {
    funcName: "offlineCoupon_pay",
    method: "POST",
    url: "/v1/offlineCoupon/pay",
  },

  // 订单确认
  // doc: http://192.168.2.18:3000/project/484/interface/api/64181
  {
    funcName: "offlineCoupon_confirm",
    method: "POST",
    url: "/v1/offlineCoupon/confirm",
  },

  /**
   * ==================================================
   *  核销端
   *  null
   * ==================================================
   */

  // 修改密码
  // doc: http://192.168.2.18:3000/project/484/interface/api/64209
  {
    funcName: "system_user_edit_pw",
    method: "POST",
    url: "/v1/system/user/edit_pw",
  },

  /**
   * ==================================================
   *  门票
   *  null
   * ==================================================
   */

  // 获取核销二维码
  // doc: http://192.168.2.18:3000/project/484/interface/api/64261
  {
    funcName: "Ticket_order_qrcode",
    method: "POST",
    url: "/v1/Ticket/order/qrcode",
  },

  /**
   * ==================================================
   *  门票
   *  null
   * ==================================================
   */

  // 门票使用时间段
  // doc: http://192.168.2.18:3000/project/484/interface/api/64273
  {
    funcName: "ticket_use_time",
    method: "GET",
    url: "/v1/ticket/use_time",
  },

  // 门票列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/64277
  {
    funcName: "ticket_list",
    method: "GET",
    url: "/v1/ticket/list",
  },

  // 门票可用日期及时间段
  // doc: http://192.168.2.18:3000/project/484/interface/api/64281
  {
    funcName: "ticket_all_use_time",
    method: "GET",
    url: "/v1/ticket/all_use_time",
  },

  // 门票详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/64285
  {
    funcName: "ticket_detail",
    method: "GET",
    url: "/v1/ticket/detail",
  },

  // 门票购买须知
  // doc: http://192.168.2.18:3000/project/484/interface/api/64289
  {
    funcName: "ticket_notice",
    method: "GET",
    url: "/v1/ticket/notice",
  },

  /**
   * ==================================================
   *  代步车
   *  null
   * ==================================================
   */

  // 代步车停放点列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/64293
  {
    funcName: "scooter_point_list",
    method: "GET",
    url: "/v1/scooter/point/list",
  },

  // 代步车停放点详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/64297
  {
    funcName: "scooter_point_detail",
    method: "GET",
    url: "/v1/scooter/point/detail",
  },

  // 代步车订单详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/64301
  {
    funcName: "scooter_order_detail",
    method: "GET",
    url: "/v1/scooter/order/detail",
  },

  // 获取租赁协议
  // doc: http://192.168.2.18:3000/project/484/interface/api/64313
  {
    funcName: "scooter_agreement",
    method: "GET",
    url: "/v1/scooter/agreement",
  },

  // 订单列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/64317
  {
    funcName: "scooter_order_list",
    method: "GET",
    url: "/v1/scooter/order/list",
  },

  // 配件列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/64369
  {
    funcName: "scooter_part_all",
    method: "GET",
    url: "/v1/scooter/part/all",
  },

  // 订单统计
  // doc: http://192.168.2.18:3000/project/484/interface/api/64373
  {
    funcName: "scooter_order_statistic",
    method: "GET",
    url: "/v1/scooter/order/statistic",
  },

  // 退车
  // doc: http://192.168.2.18:3000/project/484/interface/api/64381
  {
    funcName: "scooter_order_return",
    method: "POST",
    url: "/v1/scooter/order/return",
  },

  // 订单确认
  // doc: http://192.168.2.18:3000/project/484/interface/api/64385
  {
    funcName: "scooter_order_check",
    method: "POST",
    url: "/v1/scooter/order/check",
  },

  // 订单列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/64389
  {
    funcName: "scooter_check_list",
    method: "GET",
    url: "/v1/scooter/check_list",
  },

  // 工作人员取消游客订单
  // doc: http://192.168.2.18:3000/project/484/interface/api/64393
  {
    funcName: "scooter_order_check_cancel",
    method: "POST",
    url: "/v1/scooter/order/check_cancel",
  },

  // 订单详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/64397
  {
    funcName: "scooter_check_detail",
    method: "GET",
    url: "/v1/scooter/check/detail",
  },

  // 损坏登记
  // doc: http://192.168.2.18:3000/project/484/interface/api/64401
  {
    funcName: "scooter_damage_record",
    method: "POST",
    url: "/v1/scooter/damage_record",
  },

  // 工作人员租车订单预览
  // doc: http://192.168.2.18:3000/project/484/interface/api/64405
  {
    funcName: "scooter_worker_confirm_order",
    method: "POST",
    url: "/v1/scooter/worker/confirm_order",
  },

  // 工作人员租车订单提交
  // doc: http://192.168.2.18:3000/project/484/interface/api/64409
  {
    funcName: "scooter_worker_do_order",
    method: "POST",
    url: "/v1/scooter/worker/do_order",
  },

  // 工作人员租车记录列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/64413
  {
    funcName: "scooter_worker_order_list",
    method: "GET",
    url: "/v1/scooter/worker/order/list",
  },

  // 工作人员租车还车
  // doc: http://192.168.2.18:3000/project/484/interface/api/64417
  {
    funcName: "scooter_worker_order_return",
    method: "POST",
    url: "/v1/scooter/worker/order/return",
  },

  // 工作人员租车损坏登记
  // doc: http://192.168.2.18:3000/project/484/interface/api/64421
  {
    funcName: "scooter_worker_damage_record",
    method: "POST",
    url: "/v1/scooter/worker/damage_record",
  },

  // 工作人员租车取消
  // doc: http://192.168.2.18:3000/project/484/interface/api/64425
  {
    funcName: "scooter_worker_cancel_order",
    method: "POST",
    url: "/v1/scooter/worker/cancel_order",
  },

  // 工作人员租车记录详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/64429
  {
    funcName: "scooter_worker_detail_order",
    method: "GET",
    url: "/v1/scooter/worker/detail_order",
  },

  // 代步车详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/64437
  {
    funcName: "scooter_detail",
    method: "GET",
    url: "/v1/scooter/detail",
  },

  // 代步车更新
  // doc: http://192.168.2.18:3000/project/484/interface/api/64441
  {
    funcName: "scooter_update",
    method: "POST",
    url: "/v1/scooter/update",
  },

  /**
   * ==================================================
   *  电子发票
   *  null
   * ==================================================
   */

  // 发票列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/64357
  {
    funcName: "ei_list",
    method: "POST",
    url: "/v1/ei/list",
  },

  // 发票详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/64361
  {
    funcName: "ei_detail",
    method: "POST",
    url: "/v1/ei/detail",
  },

  // 开票
  // doc: http://192.168.2.18:3000/project/484/interface/api/64365
  {
    funcName: "ei_create",
    method: "POST",
    url: "/v1/ei/create",
  },

  /**
   * ==================================================
   *  代步车工作人员端
   *  null
   * ==================================================
   */

  // 拉入黑名单
  // doc: http://192.168.2.18:3000/project/484/interface/api/64377
  {
    funcName: "blacklist_save",
    method: "POST",
    url: "/v1/blacklist/save",
  },

  /**
   * ==================================================
   *  代步车工作人员端
   *  null
   * ==================================================
   */

  // 代步车列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/64433
  {
    funcName: "Scooter_list",
    method: "GET",
    url: "/v1/Scooter/list",
  },

  /**
   * ==================================================
   *  新闻资讯
   *  null
   * ==================================================
   */

  // 新闻资讯列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/64465
  {
    funcName: "news_list",
    method: "GET",
    url: "/v1/news/list",
  },

  // 新闻资讯详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/64469
  {
    funcName: "news_detail",
    method: "GET",
    url: "/v1/news/detail",
  },

  /**
   * ==================================================
   *  常见问题
   *  null
   * ==================================================
   */

  // 常见问题列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/64253
  {
    funcName: "problem_list",
    method: "GET",
    url: "/v1/problem/list",
  },

  /**
   * ==================================================
   *  景点
   *  null
   * ==================================================
   */

  // 景点列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/64561
  {
    funcName: "scenicSpot_list",
    method: "GET",
    url: "/v1/scenicSpot/list",
  },

  // 景点详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/64565
  {
    funcName: "scenicSpot_detail",
    method: "GET",
    url: "/v1/scenicSpot/detail",
  },

  /**
   * ==================================================
   *  景点
   *  null
   * ==================================================
   */

  // 景点分类列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/68009
  {
    funcName: "spot_categories",
    method: "GET",
    url: "/v1/spot/categories",
  },

  /**
   * ==================================================
   *  设施
   *  null
   * ==================================================
   */

  // 设施列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/64569
  {
    funcName: "facility_list",
    method: "GET",
    url: "/v1/facility/list",
  },

  // 设施详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/64573
  {
    funcName: "facility_detail",
    method: "GET",
    url: "/v1/facility/detail",
  },

  /**
   * ==================================================
   *  美图分享
   *  null
   * ==================================================
   */

  // 美图分享列表
  // doc: http://192.168.2.18:3000/project/484/interface/api/64581
  {
    funcName: "photo_list",
    method: "GET",
    url: "/v1/photo/list",
  },

  // 美图分享详情
  // doc: http://192.168.2.18:3000/project/484/interface/api/64585
  {
    funcName: "photo_detail",
    method: "GET",
    url: "/v1/photo/detail",
  },

  // 分享
  // doc: http://192.168.2.18:3000/project/484/interface/api/64589
  {
    funcName: "photo_share",
    method: "POST",
    url: "/v1/photo/share",
  },
];
