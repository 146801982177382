const commonConfig = {
  // 请求地址
  // BASE_API: "http://shop.yby.yocent.cn:8888/",
  // SHOP_API: "http://shop.yby.yocent.cn:8888/",
  BASE_API: "https://www.mszcxz.com/api",
  SHOP_API: "https://www.mszcxz.com/api",
  // 请求签名
  SIGN_KEY: "yskey.key",
};

export default {
  ...commonConfig,
};
