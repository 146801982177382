import $store from "@/store";
const Setting = {
  header: {
    hotLineLabel: {
      zh: "旅游服务热线",
      en: "Hotline",
    },
    Share: {
      zh: "美图分享",
      en: "Picture Share",
    },
    changeLang: {
      zh: "语言切换",
      en: "Lang",
    },
    Eshop: {
      zh: "电子商城",
      en: "E-shop",
    },
    Tickets: {
      zh: "门票预定",
      en: "Tickets",
    },
  },
  footer: {
    aboutUs: {
      zh: "关于我们",
      en: "About us",
    },
    place: {
      zh: "茗山智创小镇",
      en: "No. 20, Puxing Avenue, Yongning District, Nanning",
    },
    Douyin: {
      zh: "抖音",
      en: "Tik Tok",
    },
    weibo: {
      zh: "微博订阅号",
      en: "Sina Weibo",
    },
    WeChat: {
      zh: "微信订阅号",
      en: "WeChat",
    },
    Mobile: {
      zh: "移动端",
      en: "Mobile",
    },
    Copyright: {
      zh: "版权所有:南宁园博园",
      en: "Copyright belonging:Nanning Garden Expo",
    },
  },
  bread: {
    locate: {
      zh: "您所在的位置：",
      en: "Locate：",
    },
  },
  complaint: {
    title: {
      zh: "在线投诉",
      en: "Complaint",
    },
    name: {
      zh: "姓名",
      en: "Name",
    },
    namePlaceholder: {
      zh: "请输入姓名",
      en: "Please type in your name",
    },
    phone: {
      zh: "手机号码",
      en: "Phone",
    },
    phonePlaceholder: {
      zh: "请输入联系电话",
      en: "Please type your phone number",
    },
    respondent: {
      zh: "被投诉方",
      en: "Respondent",
    },
    respondentPlaceholder: {
      zh: "请输入被投诉方",
      en: "Please type respondent",
    },
    content: {
      zh: "投诉内容",
      en: "Content",
    },
    contentPlaceholder: {
      zh: "请输入投诉内容",
      en: "Please type content",
    },
    submitButton: {
      zh: "确认并提交",
      en: "Submit",
    },
  },
  suggest: {
    title: {
      zh: "游客建议",
      en: "Suggest",
    },
    type: {
      zh: "建议类型",
      en: "Type",
    },
    typePlaceholder: {
      zh: "请输入建议类型",
      en: "Please enter the suggested type",
    },
    name: {
      zh: "姓名",
      en: "Name",
    },
    namePlaceholder: {
      zh: "请输入姓名",
      en: "Please type in your name",
    },
    phone: {
      zh: "手机号码",
      en: "Phone",
    },
    phonePlaceholder: {
      zh: "请输入联系电话",
      en: "Please type your phone number",
    },
    content: {
      zh: "建议内容",
      en: "Content",
    },
    contentPlaceholder: {
      zh: "请输入建议内容",
      en: "Please type content",
    },
    submitButton: {
      zh: "确认并提交",
      en: "Submit",
    },
  },
  weather: {
    humidity: {
      zh: "湿度",
      en: "Humidity",
    },
    visibility: {
      zh: "可见度",
      en: "Visibility",
    },
    windScale: {
      zh: "级",
      en: "Level",
    },
    airQuality: {
      zh: "空气质量：",
      en: "Air quality: ",
    },
  },
  detail: {
    description: {
      zh: "描述",
      en: "Description",
    },
    ProductDetail: {
      zh: "商品详情",
      en: "Product Detail",
    },
    ProductClassify: {
      zh: "分类",
      en: "Classify",
    },
    ProductPrice: {
      zh: "价格",
      en: "Price",
    },
    WeChatScanCode: {
      zh: "微信扫码购买",
      en: "WeChat scan code to buy",
    },
    NewsPublishTime: {
      zh: "发布时间：",
      en: "Publish Time：",
    },
    NewsPublisher: {
      zh: "发布者：",
      en: "Publisher：",
    },
    NewsViews: {
      zh: "浏览次数：",
      en: "Views：",
    },
    raiderPhone: {
      zh: "电话",
      en: "Phone",
    },
    raiderPhoneNone: {
      zh: "暂无电话",
      en: "no information",
    },
    raiderAddress: {
      zh: "地址",
      en: "Address",
    },
    raiderAddressNone: {
      zh: "暂无地址",
      en: "no information",
    },
    raiderPrice: {
      zh: "价格",
      en: "Price",
    },
    raiderPriceNone: {
      zh: "暂无价格",
      en: "no information",
    },
  },
  ticket: {
    title: {
      zh: "南宁园博园门票",
      en: "Nanning Garden Expo Ticket",
    },
    ScanCode: {
      zh: "二维码扫码购买",
      en: "Scan code to buy",
    },
  },
  picUpload: {
    shareBtn: {
      zh: "分享您的美图",
      en: "Picture Share",
    },
    upload: {
      zh: "上传",
      en: "Upload",
    },
    fileUpload: {
      zh: "上传作品",
      en: "Upload works",
    },
    worksInfo: {
      zh: "作品信息",
      en: "Work information",
    },
    picUpload: {
      zh: "上传图片",
      en: "Upload image",
    },
    author: {
      zh: "请输入作者名",
      en: "Please enter the author name",
    },
    PhotographyTime: {
      zh: "请输入摄影时间",
      en: "Please enter the shooting time",
    },
    content: {
      zh: "请输入作品内容",
      en: "Please enter the content of the work",
    },
    chooseFile: {
      zh: "请选择作品",
      en: "Please choose file",
    },
    uploadPicButton: {
      zh: "上传图片",
      en: "Upload",
    },
    uploadButton: {
      zh: "发布",
      en: "Upload",
    },
    announcer: {
      zh: "发布者",
      en: "Announcer",
    },
    filmingTime: {
      zh: "拍摄时间",
      en: "Filming time",
    },
    uploadFiledMax: {
      zh: "文件上传失败,最大上传9张图片",
      en: "File upload failed, maximum upload of 9 images.",
    },
    uploadFiled: {
      zh: "文件上传失败",
      en: "File upload fail!",
    },
    shareSuccess: {
      zh: "分享成功!",
      en: "Share success!",
    },
    imageOnly: {
      zh: "只允许上传图片文件!",
      en: "You can only upload image file!",
    },
    imageSizeLimit: {
      zh: "图片大小不得超过20MB!",
      en: "Image must smaller than 20MB!",
    },
  },
  question: {
    title: {
      zh: "园博园旅游问答",
      en: "Questions and answers",
    },
  },
};

const Menus = [
  {
    zh: "首页",
    en: "Home",
    path: "home",
  },
  {
    zh: "景区概况",
    en: "Overview",
    path: "overview",
  },
  {
    zh: "景区介绍",
    en: "Introduction",
    secondMenu: [
      {
        zh: "景点介绍",
        en: "Scenic Spot",
        path: "scenic",
      },
      {
        zh: "风景图片",
        en: "Photo",
        path: "scenicPicture",
      },
      {
        zh: "风光视频",
        en: "Video",
        path: "scenicVedio",
      },
      {
        zh: "植物介绍",
        en: "Plant",
        path: "plant",
      },
      {
        zh: "虚拟旅游",
        en: "Virtual",
        path: "virtual",
      },
      {
        zh: "地图导览",
        en: "Guided",
        path: "scenicVedio",
      },
      {
        zh: "美图分享",
        en: "Picture Share",
        path: "pictureShare",
      },
    ],
  },
  {
    zh: "新闻资讯",
    en: "News",
    secondMenu: [
      {
        zh: "热门活动",
        en: "Activity",
        path: "activity",
      },
      {
        zh: "景区公告",
        en: "Announcement",
        path: "announcement",
      },
    ],
  },
  {
    zh: "交通指南",
    en: "Traffic",
    secondMenu: [
      {
        zh: "公交",
        en: "Bus",
        path: "traffic",
      },
      {
        zh: "铁路",
        en: "Railway",
        path: "traffic",
      },
      {
        zh: "飞机",
        en: "Airplane",
        path: "traffic",
      },
      {
        zh: "自驾",
        en: "Self-driving",
        path: "traffic",
      },
    ],
  },
  {
    zh: "游玩攻略",
    en: "Raiders",
    secondMenu: [
      {
        zh: "游玩攻略",
        en: "Raiders",
        path: "raiders",
      },
      {
        zh: "特色美食",
        en: "Foods",
        path: "foods",
      },
      {
        zh: "休闲娱乐",
        en: "Entertainment",
        path: "entertainment",
      },
      {
        zh: "住宿推荐",
        en: "Accommodation",
        path: "accommodation",
      },
    ],
  },
  {
    zh: "旅游保障",
    en: "Guarantee",
    secondMenu: [
      {
        zh: "常见问题",
        en: "Questions",
        path: "questions",
      },
      {
        zh: "在线投诉",
        en: "Complaint",
        path: "complaint",
      },
      {
        zh: "游客建议",
        en: "Suggestion",
        path: "suggest",
      },
      {
        zh: "天气查询",
        en: "Weather",
        path: "weather",
      },
    ],
  },
  {
    zh: "联系我们",
    en: "contact",
    path: "contact",
    //   secondMenu: [{
    //       zh: "联系电话",
    //       en: "Raiders",
    //       path: "raiders"
    //     },
    //     {
    //       zh: "联系邮箱",
    //       en: "Foods",
    //       path: "foods"
    //     },
    //     {
    //       zh: "工作职能",
    //       en: "Foods",
    //       path: "foods"
    //     },
    //   ],
  },
];

const FooterMenus = [
  {
    zh: "景区介绍",
    en: "Introduction",
    secondMenu: [
      {
        zh: "园博园概况",
        en: "Overview",
        path: "overview",
      },
      {
        zh: "植物介绍",
        en: "Plant",
        path: "plant",
      },
    ],
  },
  {
    zh: "景区介绍",
    en: "Introduction",
    secondMenu: [
      {
        zh: "景点介绍",
        en: "Scenic Spot",
        path: "scenic",
      },
      {
        zh: "风景图片",
        en: "Photo",
        path: "scenicPicture",
      },
      {
        zh: "风光视频",
        en: "Video",
        path: "scenicVedio",
      },
      {
        zh: "虚拟旅游",
        en: "Virtual",
        path: "virtual",
      },
      {
        zh: "地图导览",
        en: "Guided",
        path: "scenicVedio",
      },
      {
        zh: "美图分享",
        en: "Picture Share",
        path: "pictureShare",
      },
    ],
  },
  {
    zh: "新闻资讯",
    en: "News",
    secondMenu: [
      {
        zh: "热门活动",
        en: "Activity",
        path: "activity",
      },
      {
        zh: "景区公告",
        en: "Announcement",
        path: "announcement",
      },
    ],
  },
  {
    zh: "交通指南",
    en: "Traffic",
    secondMenu: [
      {
        zh: "公交",
        en: "Bus",
        path: "traffic",
      },
      {
        zh: "铁路",
        en: "Railway",
        path: "traffic",
      },
      {
        zh: "飞机",
        en: "Airplane",
        path: "traffic",
      },
      {
        zh: "自驾",
        en: "Self-driving",
        path: "traffic",
      },
    ],
  },
  {
    zh: "游玩攻略",
    en: "Raiders",
    secondMenu: [
      {
        zh: "游玩攻略",
        en: "Raiders",
        path: "raiders",
      },
      {
        zh: "特色美食",
        en: "Foods",
        path: "foods",
      },
      {
        zh: "休闲娱乐",
        en: "Entertainment",
        path: "entertainment",
      },
      {
        zh: "住宿推荐",
        en: "Accommodation",
        path: "accommodation",
      },
    ],
  },
  {
    zh: "旅游保障",
    en: "Guarantee",
    secondMenu: [
      {
        zh: "常见问题",
        en: "Questions",
        path: "questions",
      },
      {
        zh: "在线投诉",
        en: "Complaint",
        path: "complaint",
      },
      {
        zh: "游客建议",
        en: "Suggestion",
        path: "suggest",
      },
      {
        zh: "天气查询",
        en: "Weather",
        path: "weather",
      },
    ],
  },
  // {
  //   zh: "联系我们",
  //   en: "connect",
  //   secondMenu: [{
  //       zh: "联系电话",
  //       en: "Telephone",
  //       path: "foods"
  //     },
  //     {
  //       zh: "联系邮箱",
  //       en: "E-mail",
  //       path: "foods"
  //     },
  //     {
  //       zh: "工作职能",
  //       en: "Functions",
  //       path: "foods"
  //     },
  //   ],
  // },
];

const DetailMenus = [
  {
    zh: "资讯详情",
    en: "News Detail",
    path: "newsDetail",
  },
  {
    zh: "攻略详情",
    en: "Raiders Detail",
    path: "raidersDetail",
  },
  {
    zh: "景点详情",
    en: "Scenic Detail",
    path: "scenicDetail",
  },
  {
    zh: "商品详情",
    en: "Product Details",
    path: "shopDetail",
  },
  {
    zh: "图片上传",
    en: "Picture upload",
    path: "share",
  },
];

const Home = {
  swiperTitle: {
    zh: "images/home/title-zh.png",
    en: "images/home/title-en.png",
  },
  CardTitle1: {
    zh: "小镇景点",
    en: "Scenic Display",
  },
  CardTitle2: {
    zh: "小镇资讯",
    en: "Information",
  },
  CardTitle3: {
    zh: "游玩攻略",
    en: "Strategy",
  },
  CardTitle4: {
    zh: "园博之美",
    en: "Colorful",
  },
  virtualScenePic: {
    zh: "images/home/virtual-scenic-zh.png",
    en: "images/home/virtual-scenic-en.png",
  },
  beauty: [
    {
      img: "picture",
      zh: "园博美图",
      en: "Picture",
      path: "scenicPicture",
    },
    {
      img: "activity",
      zh: "园博活动",
      en: "Activity",
      path: "activity",
    },
    {
      img: "video",
      zh: "园博视频",
      en: "Video",
      path: "scenicVedio",
    },
    {
      img: "plant",
      zh: "园博植物",
      en: "Plant",
      path: "plant",
    },
    {
      img: "share",
      zh: "美图分享",
      en: "Share",
      path: "pictureShare",
    },
  ],
  middleToolbar: [
    {
      zh: "小镇住宿",
      en: "Traffic",
      remark: "Stay",
      icon: require("@/assets/icon/zhusu.png"),
      iconActive: "images/home/middle-traffic-active.png",
      path: "accommodation",
    },
    {
      zh: "小镇美食",
      en: "Foods",
      remark: "Delicious Food",
      icon: "images/home/middle-foods.png",
      iconActive: "images/home/middle-foods-active.png",
      // path: "foods"
      path: "repast",
    },
    {
      zh: "小镇娱乐",
      en: "Ticket",
      remark: "Entertainment",
      icon: "images/home/middle-ticket.png",
      iconActive: "images/home/middle-ticket-active.png",
      path: "entertainment",
    },
    {
      zh: "文创产品",
      en: "Shop",
      remark: "Product",
      icon: "images/home/middle-shop.png",
      iconActive: "images/home/middle-shop-active.png",
      path: "shop",
    },
    {
      zh: "游览路线",
      en: "Weather",
      remark: "Line",
      icon: require("@/assets/icon/luxian.png"),
      iconActive: "images/home/middle-weather-active.png",
      path: "scenic-guide",
    },
    {
      zh: "咨询服务",
      en: "Question",
      remark: "Service",
      icon: "images/home/middle-question.png",
      iconActive: "images/home/middle-question-active.png",
      path: "contact",
    },
  ],
  BgTitle: {
    zh: "醉美南宁园博园",
    en: "醉美南宁园博园",
  },
  BgTitleClick: {
    zh: "点击查看虚拟产场景",
    en: "Click to view virtual production scene",
  },
  Notice: {
    zh: "公告：",
    en: "Notice：",
  },
  Picture: {
    zh: "风景图片",
    en: "Picture",
  },
  Video: {
    zh: "视频风光",
    en: "Video",
  },
  MapNavigation: {
    zh: "地图导览",
    en: "Map Navigation",
  },
  KnownMore: {
    zh: "了解更多",
    en: "More",
  },
  More: {
    zh: "查看更多",
    en: "More Information",
  },
  Detail: {
    zh: "查看详情",
    en: "Detail",
  },
  NoDescription: {
    zh: "暂无描述",
    en: "No description",
  },
  Link: {
    zh: "友情链接",
    en: "Link",
  },
};
//头部底部菜单栏高度
// const headerHeight = document.getElementsByClassName('header-bottom')[0].offsetHeight;

function getPageSetting() {
  //可视区域高度
  const windowHeight = document.body.clientHeight;
  //可视区域宽度
  const windowWidth = document.body.clientWidth;
  //剔除折叠头部后剩下的页面高度
  // const pageHeight = windowHeight - headerHeight;

  return {
    windowHeight: windowHeight,
    windowWidth: windowWidth,
  };
}

function getSettingLang() {
  return $store.getters.getLang;
}

function getSetting(settingKey) {
  const settingLang = getSettingLang();
  let mySetting = {};
  for (var key in Setting[settingKey]) {
    mySetting[key] = Setting[settingKey][key][settingLang];
  }
  return mySetting;
}

function getHomeSetting() {
  const settingLang = getSettingLang();
  let homeSetting = {};
  let obj = {};
  for (var key in Home) {
    if (Home[key] instanceof Array) {
      homeSetting[key] = Home[key].map((obj) => {
        obj = {
          ...obj,
          title: obj[settingLang],
          icon: obj.icon || "",
        };
        return obj;
      });
    } else {
      homeSetting[key] = Home[key][settingLang];
    }
  }
  return homeSetting;
}

function getHeaderMenus() {
  const settingLang = getSettingLang();
  let menuList = Menus.map((menu) => {
    let menuData = {
      ...menu,
      isActive: false,
      title: menu[settingLang],
    };
    // if (menu.path) {
    //   menuData.path = menu.path
    // }
    if (menu.secondMenu) {
      menu.secondMenu.map((sec) => {
        sec.title = sec[settingLang];
        return;
      });
      menuData.menu = menu.secondMenu;
    }
    return menuData;
  });
  return menuList;
}

function getFooterMenus() {
  const settingLang = getSettingLang();
  let menuData = {};
  let secList = [];
  let menuList = FooterMenus.map((menu) => {
    menuData = {
      ...menu,
      title: menu[settingLang],
    };
    secList = menu.secondMenu.map((m) => {
      return {
        ...m,
        title: m[settingLang],
      };
    });
    menuData.secList = secList;
    return menuData;
  });
  return menuList;
}

function getDetailMenus() {
  const settingLang = getSettingLang();
  let detailMenus = DetailMenus.map((dMenu) => {
    return {
      ...dMenu,
      title: dMenu[settingLang],
    };
  });
  return detailMenus;
}

function getAllSetting() {
  return {
    PageSetting: getPageSetting(),
    HomeSetting: getHomeSetting(),
    HeaderSetting: getSetting("header"),
    FooterSetting: getSetting("footer"),
    BreadSetting: getSetting("bread"),
    ComplaintSetting: getSetting("complaint"),
    SuggestSetting: getSetting("suggest"),
    WeatherSetting: getSetting("weather"),
    DetailSetting: getSetting("detail"),
    TicketSetting: getSetting("ticket"),
    PicUpload: getSetting("picUpload"),
    Question: getSetting("question"),
    // HeaderMenus: getHeaderMenus(),
    FooterMenus: getFooterMenus(),
    // DetailMenus:getDetailMenus(),
  };
}

export { getPageSetting, getAllSetting };
