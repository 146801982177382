import { createRouter, createWebHashHistory } from "vue-router";
import $store from "@/store";
import pathMatch from "./pathMatch.js";

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      name: "home",
      component: () => import("@/view/index/index"),
      meta: {
        level: 1,
      },
    },
    {
      path: "/ticket",
      name: "ticket",
      component: () => import("@/view/tickets/tickets"),
      meta: {
        title: "门票预定",
        level: 2,
      },
    },
    {
      path: "/ticketsPolicy",
      name: "ticketsPolicy",
      component: () => import("@/view/tickets/policy"),
      meta: {
        title: "门票政策",
        level: 2,
      },
    },
    {
      path: "/shop",
      name: "shop",
      component: () => import("@/view/shop/shop"),
      meta: {
        title: "商城",
        level: 2,
      },
    },
    {
      path: "/shopDetail",
      name: "shopDetail",
      component: () => import("@/view/shop/shopDetail"),
      meta: {
        title: "商品详情",
        level: 3,
      },
    },
    {
      path: "/overview",
      name: "overview",
      component: () => import("@/view/index/overview"),
      meta: {
        title: "园博园概况",
        level: 2,
      },
    },
    {
      path: "/activity",
      name: "activity",
      component: () => import("@/view/news/activity"),
      meta: {
        title: "热门活动",
        level: 2,
      },
    },
    {
      path: "/announcement",
      name: "announcement",
      component: () => import("@/view/news/announcement"),
      meta: {
        title: "景区公告",
        level: 2,
      },
    },
    {
      path: "/news",
      name: "news",
      component: () => import("@/view/news/news"),
      meta: {
        title: "资讯",
        level: 2,
      },
    },
    {
      path: "/creation",
      name: "creation",
      component: () => import("@/view/news/creation"),
      meta: {
        title: "标准化创建专栏",
        level: 2,
      },
    },
    {
      path: "/creation-detail",
      name: "creation-detail",
      component: () => import("@/view/news/creation-detail"),
      meta: {
        title: "标准化创建专栏详情",
        level: 3,
      },
    },
    {
      path: "/news-detail",
      name: "news-detail",
      component: () => import("@/view/news/news-detail"),
      meta: {
        title: "资讯详情",
        level: 3,
      },
    },
    {
      path: "/announcement-detail",
      name: "announcement-detail",
      component: () => import("@/view/news/announcement-detail"),
      meta: {
        title: "景区公告详情",
        level: 3,
      },
    },
    {
      path: "/activity-detail",
      name: "activity-detail",
      component: () => import("@/view/news/activity-detail"),
      meta: {
        title: "热门活动详情",
        level: 3,
      },
    },
    {
      path: "/raiders",
      name: "raiders",
      component: () => import("@/view/raiders/raiders"),
      meta: {
        title: "游玩攻略",
        level: 2,
      },
    },
    {
      path: "/foods",
      name: "foods",
      component: () => import("@/view/raiders/foods"),
      meta: {
        title: "特色美食",
        level: 2,
      },
    },
    {
      path: "/repast",
      name: "repast",
      component: () => import("@/view/raiders/repast"),
      meta: {
        title: "周边餐饮",
        level: 2,
      },
    },
    {
      path: "/accommodation",
      name: "accommodation",
      component: () => import("@/view/raiders/accommodation"),
      meta: {
        title: "住宿推荐",
        level: 2,
      },
    },
    {
      path: "/entertainment",
      name: "entertainment",
      component: () => import("@/view/raiders/entertainment"),
      meta: {
        title: "休闲娱乐",
        level: 2,
      },
    },
    {
      path: "/food-detail",
      name: "food-detail",
      component: () => import("@/view/raiders/food-detail"),
      meta: {
        title: "美食详情",
        level: 2,
      },
    },
    {
      path: "/raiders-detail",
      name: "raiders-detail",
      component: () => import("@/view/raiders/raiders-detail"),
      meta: {
        title: "攻略详情",
        level: 3,
      },
    },
    {
      path: "/raidersDetailMap",
      name: "raidersDetailMap",
      component: () => import("@/view/raiders/detail-map"),
      meta: {
        title: "攻略详情",
        level: 3,
      },
    },
    {
      path: "/RepastDetail",
      name: "RepastDetail",
      component: () => import("@/view/raiders/detail-map"),
      meta: {
        title: "餐饮详情",
        level: 3,
      },
    },
    {
      path: "/EntertainmentDetail",
      name: "EntertainmentDetail",
      component: () => import("@/view/raiders/entertainment-detail"),
      meta: {
        title: "娱乐详情",
        level: 3,
      },
    },
    {
      path: "/AccommodationDetail",
      name: "AccommodationDetail",
      component: () => import("@/view/raiders/hotel-detail"),
      meta: {
        title: "住宿详情",
        level: 3,
      },
    },
    {
      path: "/questions",
      name: "questions",
      component: () => import("@/view/assure/questions"),
      meta: {
        title: "常见问题",
        level: 2,
      },
    },
    {
      path: "/complaint",
      name: "complaint",
      component: () => import("@/view/assure/complaint"),
      meta: {
        title: "投诉",
        level: 2,
      },
    },
    {
      path: "/suggest",
      name: "suggest",
      component: () => import("@/view/assure/suggest"),
      meta: {
        title: "建议",
        level: 2,
      },
    },
    {
      path: "/weather",
      name: "weather",
      component: () => import("@/view/assure/weather"),
      meta: {
        title: "天气",
        level: 2,
      },
    },
    {
      path: "/scenic",
      name: "scenic",
      component: () => import("@/view/scenic/scenic"),
      meta: {
        title: "景区介绍",
        level: 2,
      },
    },
    {
      path: "/scenicDetail",
      name: "scenicDetail",
      component: () => import("@/view/scenic/scenicDetail"),
      meta: {
        title: "景区详情",
        level: 3,
      },
    },
    {
      path: "/scenicPicture",
      name: "scenicPicture",
      component: () => import("@/view/scenic/scenicPicture"),
      meta: {
        title: "风景图片",
        level: 2,
      },
    },
    {
      path: "/scenicVedio",
      name: "scenicVedio",
      component: () => import("@/view/scenic/scenicVedio"),
      meta: {
        title: "风光视频",
        level: 2,
      },
    },
    {
      path: "/scenic-guide",
      name: "scenic-guide",
      component: () => import("@/view/scenic/scenic-guide"),
      meta: {
        title: "浏览线路",
        level: 2,
      },
    },
    {
      path: "/guide-detail",
      name: "guide-detail",
      component: () => import("@/view/scenic/guide-detail"),
      meta: {
        title: "浏览线路",
        level: 3,
      },
    },
    {
      path: "/scenicGuided",
      name: "scenicGuided",
      component: () => import("@/view/scenicGuided/scenicGuided"),
      meta: {
        title: "地图导览",
        level: 1,
      },
    },
    {
      path: "/plant",
      name: "plant",
      component: () => import("@/view/plant/plant"),
      meta: {
        title: "植物介绍",
        level: 2,
      },
    },
    {
      path: "/plantDetail",
      name: "plantDetail",
      component: () => import("@/view/plant/plantDetail"),
      meta: {
        title: "植物详情",
        level: 3,
      },
    },
    {
      path: "/virtual",
      name: "virtual",
      component: () => import("@/view/virtual/virtual"),
      meta: {
        title: "虚拟旅游",
        level: 2,
      },
    },
    {
      path: "/pictureShare",
      name: "pictureShare",
      component: () => import("@/view/pictureShare/pictureShare"),
      meta: {
        title: "美图分享",
        level: 2,
      },
    },
    {
      path: "/share",
      name: "share",
      component: () => import("@/view/pictureShare/share"),
      meta: {
        title: "美图上传",
        level: 2,
      },
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("@/view/contact/contact"),
      meta: {
        title: "咨询服务",
        level: 2,
      },
    },
    {
      path: "/traffic",
      name: "traffic",
      component: () => import("@/view/traffic/traffic"),
      meta: {
        title: "交通指南",
        level: 2,
      },
    },
    {
      path: "/trafficScenic",
      name: "trafficScenic",
      component: () => import("@/view/traffic/scenic"),
      meta: {
        title: "园区交通",
        level: 2,
      },
    },
    {
      path: "/knowledge",
      name: "knowledge",
      component: () => import("@/view/knowledge/knowledge"),
      meta: {
        title: "园博科普",
        level: 2,
      },
    },
    {
      path: "/knowledge-detail",
      name: "knowledge-detail",
      component: () => import("@/view/knowledge/detail"),
      meta: {
        title: "科普详情",
        level: 3,
      },
    },
    {
      path: "/booking",
      name: "booking",
      component: () => import("@/view/booking/booking"),
      meta: {
        title: "园博预约",
        level: 2,
      },
    },
    {
      path: "/search",
      name: "search",
      component: () => import("@/view/search/search"),
      meta: {
        title: "搜索",
        level: 1,
      },
    },
    {
      path: "/server",
      name: "server",
      component: () => import("@/view/contact/server"),
      meta: {
        title: "公共服务",
        level: 2,
      },
    },
    {
      path: "/server-detail",
      name: "server-detail",
      component: () => import("@/view/contact/server-detail"),
      meta: {
        title: "重点项目",
        level: 3,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  $store.dispatch("routerChange", to);

  /* 进入新页面自动回到页面顶部 */
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
  next();
});

// router.afterEach((to, from) => {
//   console.log(to);
//   console.log(from);
// })
export default router;
