/* eslint-disable */
export default [
  /**
   * ==================================================
   *  公共分类
   *  公共分类
   * ==================================================
   */

  // 验证码-发送短信验证码
  // doc: http://192.168.2.18:3000/project/779/interface/api/137985
  {
    funcName: "Index_sendCode",
    method: "POST",
    url: "/frontend/Index/sendCode",
  },

  // 用户密码登录
  // doc: http://192.168.2.18:3000/project/779/interface/api/137989
  {
    funcName: "Index_passwordLogin",
    method: "POST",
    url: "/frontend/Index/passwordLogin",
  },

  // 短信验证码登录
  // doc: http://192.168.2.18:3000/project/779/interface/api/137993
  {
    funcName: "Index_smsLogin",
    method: "POST",
    url: "/frontend/Index/smsLogin",
  },

  // 短信验证码登录，并自动注册
  // doc: http://192.168.2.18:3000/project/779/interface/api/137997
  {
    funcName: "Index_smsLoginAutoReg",
    method: "POST",
    url: "/frontend/Index/smsLoginAutoReg",
  },

  // 用户注册
  // doc: http://192.168.2.18:3000/project/779/interface/api/138001
  {
    funcName: "Index_register",
    method: "POST",
    url: "/frontend/Index/register",
  },

  // 获取开放的配置信息
  // doc: http://192.168.2.18:3000/project/779/interface/api/138005
  {
    funcName: "Index_getOpenSystemConfig",
    method: "GET",
    url: "/frontend/Index/getOpenSystemConfig",
  },

  // 用户名校验
  // doc: http://192.168.2.18:3000/project/779/interface/api/138009
  {
    funcName: "Index_checkUsername",
    method: "GET",
    url: "/frontend/Index/checkUsername",
  },

  /**
   * ==================================================
   *  公共分类
   *  公共分类
   * ==================================================
   */

  // 文件上传
  // doc: http://192.168.2.18:3000/project/779/interface/api/138665
  {
    funcName: "Upload_upload",
    method: "POST",
    url: "/frontend/Upload/upload",
  },

  // 分片上传
  // doc: http://192.168.2.18:3000/project/779/interface/api/138669
  {
    funcName: "Upload_chunk",
    method: "POST",
    url: "/frontend/Upload/chunk",
  },

  // 文件md5检查
  // doc: http://192.168.2.18:3000/project/779/interface/api/138673
  {
    funcName: "Upload_md5Check",
    method: "POST",
    url: "/frontend/Upload/md5Check",
  },

  // 分片上传检测
  // doc: http://192.168.2.18:3000/project/779/interface/api/138677
  {
    funcName: "Upload_chunkCheck",
    method: "POST",
    url: "/frontend/Upload/chunkCheck",
  },

  // 分片合并
  // doc: http://192.168.2.18:3000/project/779/interface/api/138681
  {
    funcName: "Upload_chunksMerge",
    method: "POST",
    url: "/frontend/Upload/chunksMerge",
  },

  /**
   * ==================================================
   *  公共分类
   *  公共分类
   * ==================================================
   */

  // 用户退出
  // doc: http://192.168.2.18:3000/project/779/interface/api/138693
  {
    funcName: "User_logout",
    method: "POST",
    url: "/frontend/User/logout",
  },

  // 更新会员信息
  // doc: http://192.168.2.18:3000/project/779/interface/api/138685
  {
    funcName: "User_updateUserInfo",
    method: "POST",
    url: "/frontend/User/updateUserInfo",
  },

  // 获取会员信息
  // doc: http://192.168.2.18:3000/project/779/interface/api/138689
  {
    funcName: "User_getUserInfo",
    method: "GET",
    url: "/frontend/User/getUserInfo",
  },

  // 设置用户标签
  // doc: http://192.168.2.18:3000/project/779/interface/api/138697
  {
    funcName: "User_saveUserTags",
    method: "POST",
    url: "/frontend/User/saveUserTags",
  },

  // 获取会员积分
  // doc: http://192.168.2.18:3000/project/779/interface/api/138701
  {
    funcName: "User_getUserScore",
    method: "GET",
    url: "/frontend/User/getUserScore",
  },

  // 获取会员余额
  // doc: http://192.168.2.18:3000/project/779/interface/api/138705
  {
    funcName: "User_getUseMoney",
    method: "GET",
    url: "/frontend/User/getUseMoney",
  },

  /**
   * ==================================================
   *  广告
   *  广告
   * ==================================================
   */

  // 广告列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137721
  {
    funcName: "Ad_query",
    method: "GET",
    url: "/frontend/Ad/query",
  },

  // 广告获取详情
  // doc: http://192.168.2.18:3000/project/779/interface/api/137725
  {
    funcName: "Ad_read",
    method: "GET",
    url: "/frontend/Ad/read",
  },

  /**
   * ==================================================
   *  广告位
   *  广告位
   * ==================================================
   */

  // 广告位上的广告获取详情
  // doc: http://192.168.2.18:3000/project/779/interface/api/137729
  {
    funcName: "AdPosition_read",
    method: "GET",
    url: "/frontend/AdPosition/read",
  },

  /**
   * ==================================================
   *  资讯
   *  资讯
   * ==================================================
   */

  // 资讯列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137733
  {
    funcName: "Article_query",
    method: "GET",
    url: "/frontend/Article/query",
  },

  // 资讯获取详情
  // doc: http://192.168.2.18:3000/project/779/interface/api/137737
  {
    funcName: "Article_read",
    method: "GET",
    url: "/frontend/Article/read",
  },

  // 资讯发布评论
  // doc: http://192.168.2.18:3000/project/779/interface/api/137741
  {
    funcName: "Article_saveComment",
    method: "POST",
    url: "/frontend/Article/saveComment",
  },

  // 资讯评论列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137745
  {
    funcName: "Article_queryComment",
    method: "GET",
    url: "/frontend/Article/queryComment",
  },

  // 资讯评论删除
  // doc: http://192.168.2.18:3000/project/779/interface/api/137749
  {
    funcName: "Article_deleteComment",
    method: "POST",
    url: "/frontend/Article/deleteComment",
  },

  // 资讯足迹保存
  // doc: http://192.168.2.18:3000/project/779/interface/api/137753
  {
    funcName: "Article_saveVisit",
    method: "POST",
    url: "/frontend/Article/saveVisit",
  },

  // 资讯足迹列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137757
  {
    funcName: "Article_queryVisit",
    method: "GET",
    url: "/frontend/Article/queryVisit",
  },

  // 资讯收藏
  // doc: http://192.168.2.18:3000/project/779/interface/api/137761
  {
    funcName: "Article_saveCollection",
    method: "POST",
    url: "/frontend/Article/saveCollection",
  },

  // 资讯取消收藏
  // doc: http://192.168.2.18:3000/project/779/interface/api/137765
  {
    funcName: "Article_deleteCollection",
    method: "POST",
    url: "/frontend/Article/deleteCollection",
  },

  // 资讯点赞
  // doc: http://192.168.2.18:3000/project/779/interface/api/137769
  {
    funcName: "Article_saveLike",
    method: "POST",
    url: "/frontend/Article/saveLike",
  },

  // 资讯取消点赞
  // doc: http://192.168.2.18:3000/project/779/interface/api/137773
  {
    funcName: "Article_deleteLike",
    method: "POST",
    url: "/frontend/Article/deleteLike",
  },

  // 资讯点赞列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137777
  {
    funcName: "Article_queryLike",
    method: "GET",
    url: "/frontend/Article/queryLike",
  },

  // 资讯分享
  // doc: http://192.168.2.18:3000/project/779/interface/api/137781
  {
    funcName: "Article_saveShare",
    method: "POST",
    url: "/frontend/Article/saveShare",
  },

  // 阅读资讯积分获取接口
  // doc: http://192.168.2.18:3000/project/779/interface/api/137785
  {
    funcName: "Article_readCreditRecord",
    method: "POST",
    url: "/frontend/Article/readCreditRecord",
  },

  /**
   * ==================================================
   *  资讯分类
   *  资讯分类
   * ==================================================
   */

  // 资讯分类列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137789
  {
    funcName: "ArticleCategory_query",
    method: "GET",
    url: "/frontend/ArticleCategory/query",
  },

  // 资讯分类全部列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137793
  {
    funcName: "ArticleCategory_all",
    method: "GET",
    url: "/frontend/ArticleCategory/all",
  },

  /**
   * ==================================================
   *  咨询投诉
   *  咨询投诉
   * ==================================================
   */

  // 咨询投诉列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137797
  {
    funcName: "Ask_query",
    method: "GET",
    url: "/frontend/Ask/query",
  },

  // 咨询投诉新增
  // doc: http://192.168.2.18:3000/project/779/interface/api/137801
  {
    funcName: "Ask_save",
    method: "POST",
    url: "/frontend/Ask/save",
  },

  // 咨询投诉获取详情
  // doc: http://192.168.2.18:3000/project/779/interface/api/137805
  {
    funcName: "Ask_read",
    method: "GET",
    url: "/frontend/Ask/read",
  },

  // 追问咨询投诉
  // doc: http://192.168.2.18:3000/project/779/interface/api/137809
  {
    funcName: "Ask_ask",
    method: "POST",
    url: "/frontend/Ask/ask",
  },

  // 关闭咨询投诉
  // doc: http://192.168.2.18:3000/project/779/interface/api/137813
  {
    funcName: "Ask_close",
    method: "POST",
    url: "/frontend/Ask/close",
  },

  // 打分评价咨询投诉
  // doc: http://192.168.2.18:3000/project/779/interface/api/137817
  {
    funcName: "Ask_star",
    method: "POST",
    url: "/frontend/Ask/star",
  },

  /**
   * ==================================================
   *  用户收藏
   *  用户收藏
   * ==================================================
   */

  // 我的用户收藏列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137821
  {
    funcName: "Collection_myQuery",
    method: "GET",
    url: "/frontend/Collection/myQuery",
  },

  // 我的收藏总数
  // doc: http://192.168.2.18:3000/project/779/interface/api/137825
  {
    funcName: "Collection_myNumber",
    method: "GET",
    url: "/frontend/Collection/myNumber",
  },

  /**
   * ==================================================
   *  用户评论
   *  用户评论
   * ==================================================
   */

  // 我的用户评论列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137829
  {
    funcName: "Comment_myQuery",
    method: "GET",
    url: "/frontend/Comment/myQuery",
  },

  // 我的评论总数
  // doc: http://192.168.2.18:3000/project/779/interface/api/137833
  {
    funcName: "Comment_myNumber",
    method: "GET",
    url: "/frontend/Comment/myNumber",
  },

  /**
   * ==================================================
   *  公共设施
   *  公共设施
   * ==================================================
   */

  // 公共设施列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137837
  {
    funcName: "Facility_query",
    method: "GET",
    url: "/frontend/Facility/query",
  },

  // 公共设施获取详情
  // doc: http://192.168.2.18:3000/project/779/interface/api/137841
  {
    funcName: "Facility_read",
    method: "GET",
    url: "/frontend/Facility/read",
  },

  // 公共设施发布评论
  // doc: http://192.168.2.18:3000/project/779/interface/api/137845
  {
    funcName: "Facility_saveComment",
    method: "POST",
    url: "/frontend/Facility/saveComment",
  },

  // 公共设施评论列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137849
  {
    funcName: "Facility_queryComment",
    method: "GET",
    url: "/frontend/Facility/queryComment",
  },

  // 公共设施评论删除
  // doc: http://192.168.2.18:3000/project/779/interface/api/137853
  {
    funcName: "Facility_deleteComment",
    method: "POST",
    url: "/frontend/Facility/deleteComment",
  },

  // 公共设施足迹保存
  // doc: http://192.168.2.18:3000/project/779/interface/api/137857
  {
    funcName: "Facility_saveVisit",
    method: "POST",
    url: "/frontend/Facility/saveVisit",
  },

  // 公共设施足迹列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137861
  {
    funcName: "Facility_queryVisit",
    method: "GET",
    url: "/frontend/Facility/queryVisit",
  },

  // 公共设施收藏
  // doc: http://192.168.2.18:3000/project/779/interface/api/137865
  {
    funcName: "Facility_saveCollection",
    method: "POST",
    url: "/frontend/Facility/saveCollection",
  },

  // 公共设施取消收藏
  // doc: http://192.168.2.18:3000/project/779/interface/api/137869
  {
    funcName: "Facility_deleteCollection",
    method: "POST",
    url: "/frontend/Facility/deleteCollection",
  },

  // 公共设施点赞
  // doc: http://192.168.2.18:3000/project/779/interface/api/137873
  {
    funcName: "Facility_saveLike",
    method: "POST",
    url: "/frontend/Facility/saveLike",
  },

  // 公共设施取消点赞
  // doc: http://192.168.2.18:3000/project/779/interface/api/137877
  {
    funcName: "Facility_deleteLike",
    method: "POST",
    url: "/frontend/Facility/deleteLike",
  },

  // 公共设施点赞列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137881
  {
    funcName: "Facility_queryLike",
    method: "GET",
    url: "/frontend/Facility/queryLike",
  },

  // 公共设施分享
  // doc: http://192.168.2.18:3000/project/779/interface/api/137885
  {
    funcName: "Facility_saveShare",
    method: "POST",
    url: "/frontend/Facility/saveShare",
  },

  // 阅读公共设施积分获取接口
  // doc: http://192.168.2.18:3000/project/779/interface/api/137889
  {
    funcName: "Facility_readCreditRecord",
    method: "POST",
    url: "/frontend/Facility/readCreditRecord",
  },

  // 公共设施打卡
  // doc: http://192.168.2.18:3000/project/779/interface/api/137893
  {
    funcName: "Facility_savePunch",
    method: "POST",
    url: "/frontend/Facility/savePunch",
  },

  // 查询用户在公共设施最后一次打卡记录
  // doc: http://192.168.2.18:3000/project/779/interface/api/137897
  {
    funcName: "Facility_readMyLastPunch",
    method: "GET",
    url: "/frontend/Facility/readMyLastPunch",
  },

  /**
   * ==================================================
   *  公共设施分类
   *  公共设施分类
   * ==================================================
   */

  // 公共设施分类列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137901
  {
    funcName: "FacilityCategory_query",
    method: "GET",
    url: "/frontend/FacilityCategory/query",
  },

  // 公共设施分类全部列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137905
  {
    funcName: "FacilityCategory_all",
    method: "GET",
    url: "/frontend/FacilityCategory/all",
  },

  /**
   * ==================================================
   *  酒店
   *  酒店
   * ==================================================
   */

  // 酒店列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137909
  {
    funcName: "Hotel_query",
    method: "GET",
    url: "/frontend/Hotel/query",
  },

  // 酒店获取详情
  // doc: http://192.168.2.18:3000/project/779/interface/api/137913
  {
    funcName: "Hotel_read",
    method: "GET",
    url: "/frontend/Hotel/read",
  },

  // 酒店发布评论
  // doc: http://192.168.2.18:3000/project/779/interface/api/137917
  {
    funcName: "Hotel_saveComment",
    method: "POST",
    url: "/frontend/Hotel/saveComment",
  },

  // 酒店评论列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137921
  {
    funcName: "Hotel_queryComment",
    method: "GET",
    url: "/frontend/Hotel/queryComment",
  },

  // 酒店评论删除
  // doc: http://192.168.2.18:3000/project/779/interface/api/137925
  {
    funcName: "Hotel_deleteComment",
    method: "POST",
    url: "/frontend/Hotel/deleteComment",
  },

  // 酒店足迹保存
  // doc: http://192.168.2.18:3000/project/779/interface/api/137929
  {
    funcName: "Hotel_saveVisit",
    method: "POST",
    url: "/frontend/Hotel/saveVisit",
  },

  // 酒店足迹列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137933
  {
    funcName: "Hotel_queryVisit",
    method: "GET",
    url: "/frontend/Hotel/queryVisit",
  },

  // 酒店收藏
  // doc: http://192.168.2.18:3000/project/779/interface/api/137937
  {
    funcName: "Hotel_saveCollection",
    method: "POST",
    url: "/frontend/Hotel/saveCollection",
  },

  // 酒店取消收藏
  // doc: http://192.168.2.18:3000/project/779/interface/api/137941
  {
    funcName: "Hotel_deleteCollection",
    method: "POST",
    url: "/frontend/Hotel/deleteCollection",
  },

  // 酒店点赞
  // doc: http://192.168.2.18:3000/project/779/interface/api/137945
  {
    funcName: "Hotel_saveLike",
    method: "POST",
    url: "/frontend/Hotel/saveLike",
  },

  // 酒店取消点赞
  // doc: http://192.168.2.18:3000/project/779/interface/api/137949
  {
    funcName: "Hotel_deleteLike",
    method: "POST",
    url: "/frontend/Hotel/deleteLike",
  },

  // 酒店点赞列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137953
  {
    funcName: "Hotel_queryLike",
    method: "GET",
    url: "/frontend/Hotel/queryLike",
  },

  // 酒店分享
  // doc: http://192.168.2.18:3000/project/779/interface/api/137957
  {
    funcName: "Hotel_saveShare",
    method: "POST",
    url: "/frontend/Hotel/saveShare",
  },

  // 浏览酒店积分获取接口
  // doc: http://192.168.2.18:3000/project/779/interface/api/137961
  {
    funcName: "Hotel_readCreditRecord",
    method: "POST",
    url: "/frontend/Hotel/readCreditRecord",
  },

  /**
   * ==================================================
   *  酒店分类
   *  酒店分类
   * ==================================================
   */

  // 酒店分类列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137965
  {
    funcName: "HotelCategory_query",
    method: "GET",
    url: "/frontend/HotelCategory/query",
  },

  // 酒店分类所有列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137969
  {
    funcName: "HotelCategory_all",
    method: "GET",
    url: "/frontend/HotelCategory/all",
  },

  /**
   * ==================================================
   *  酒店设施类型
   *  酒店设施类型
   * ==================================================
   */

  // 酒店设施类型列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137973
  {
    funcName: "HotelFacilityType_query",
    method: "GET",
    url: "/frontend/HotelFacilityType/query",
  },

  /**
   * ==================================================
   *  酒店房型
   *  酒店房型
   * ==================================================
   */

  // 指定酒店房型列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/137977
  {
    funcName: "HotelRoom_query",
    method: "GET",
    url: "/frontend/HotelRoom/query",
  },

  // 酒店房型获取详情
  // doc: http://192.168.2.18:3000/project/779/interface/api/137981
  {
    funcName: "HotelRoom_read",
    method: "GET",
    url: "/frontend/HotelRoom/read",
  },

  /**
   * ==================================================
   *  站内信
   *  站内信
   * ==================================================
   */

  // 消息列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138013
  {
    funcName: "Notification_query",
    method: "GET",
    url: "/frontend/Notification/query",
  },

  // 消息获取详情
  // doc: http://192.168.2.18:3000/project/779/interface/api/138017
  {
    funcName: "Notification_read",
    method: "GET",
    url: "/frontend/Notification/read",
  },

  // 消息删除
  // doc: http://192.168.2.18:3000/project/779/interface/api/138021
  {
    funcName: "Notification_delete",
    method: "POST",
    url: "/frontend/Notification/delete",
  },

  // 消息标记已读
  // doc: http://192.168.2.18:3000/project/779/interface/api/138025
  {
    funcName: "Notification_markAsRead",
    method: "POST",
    url: "/frontend/Notification/markAsRead",
  },

  // 获取新的消息数量，长轮询
  // doc: http://192.168.2.18:3000/project/779/interface/api/138029
  {
    funcName: "Notification_news",
    method: "GET",
    url: "/frontend/Notification/news",
  },

  // 获取新消息列表所有数据
  // doc: http://192.168.2.18:3000/project/779/interface/api/138033
  {
    funcName: "Notification_newsAll",
    method: "GET",
    url: "/frontend/Notification/newsAll",
  },

  /**
   * ==================================================
   *  单页
   *  单页
   * ==================================================
   */

  // 单页列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138037
  {
    funcName: "Page_query",
    method: "GET",
    url: "/frontend/Page/query",
  },

  // 单页获取详情
  // doc: http://192.168.2.18:3000/project/779/interface/api/138041
  {
    funcName: "Page_read",
    method: "GET",
    url: "/frontend/Page/read",
  },

  /**
   * ==================================================
   *  停车场
   *  停车场
   * ==================================================
   */

  // 停车场列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138045
  {
    funcName: "Parking_query",
    method: "GET",
    url: "/frontend/Parking/query",
  },

  // 停车场获取详情
  // doc: http://192.168.2.18:3000/project/779/interface/api/138049
  {
    funcName: "Parking_read",
    method: "GET",
    url: "/frontend/Parking/read",
  },

  // 停车场发布评论
  // doc: http://192.168.2.18:3000/project/779/interface/api/138053
  {
    funcName: "Parking_saveComment",
    method: "POST",
    url: "/frontend/Parking/saveComment",
  },

  // 停车场评论列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138057
  {
    funcName: "Parking_queryComment",
    method: "GET",
    url: "/frontend/Parking/queryComment",
  },

  // 停车场评论删除
  // doc: http://192.168.2.18:3000/project/779/interface/api/138061
  {
    funcName: "Parking_deleteComment",
    method: "POST",
    url: "/frontend/Parking/deleteComment",
  },

  // 停车场足迹保存
  // doc: http://192.168.2.18:3000/project/779/interface/api/138065
  {
    funcName: "Parking_saveVisit",
    method: "POST",
    url: "/frontend/Parking/saveVisit",
  },

  // 停车场足迹列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138069
  {
    funcName: "Parking_queryVisit",
    method: "GET",
    url: "/frontend/Parking/queryVisit",
  },

  // 停车场收藏
  // doc: http://192.168.2.18:3000/project/779/interface/api/138073
  {
    funcName: "Parking_saveCollection",
    method: "POST",
    url: "/frontend/Parking/saveCollection",
  },

  // 停车场取消收藏
  // doc: http://192.168.2.18:3000/project/779/interface/api/138077
  {
    funcName: "Parking_deleteCollection",
    method: "POST",
    url: "/frontend/Parking/deleteCollection",
  },

  // 停车场点赞(不知道停车场是否需要点赞功能
  // doc: http://192.168.2.18:3000/project/779/interface/api/138081
  {
    funcName: "Parking_saveLike",
    method: "POST",
    url: "/frontend/Parking/saveLike",
  },

  // 停车场取消点赞
  // doc: http://192.168.2.18:3000/project/779/interface/api/138085
  {
    funcName: "Parking_deleteLike",
    method: "POST",
    url: "/frontend/Parking/deleteLike",
  },

  // 停车场点赞列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138089
  {
    funcName: "Parking_queryLike",
    method: "GET",
    url: "/frontend/Parking/queryLike",
  },

  // 停车场分享
  // doc: http://192.168.2.18:3000/project/779/interface/api/138093
  {
    funcName: "Parking_saveShare",
    method: "POST",
    url: "/frontend/Parking/saveShare",
  },

  // 阅读停车场积分获取接口
  // doc: http://192.168.2.18:3000/project/779/interface/api/138097
  {
    funcName: "Parking_readCreditRecord",
    method: "POST",
    url: "/frontend/Parking/readCreditRecord",
  },

  /**
   * ==================================================
   *  二维码
   *  二维码
   * ==================================================
   */

  // 根据内容 响应输出二维码图片
  // doc: http://192.168.2.18:3000/project/779/interface/api/138101
  {
    funcName: "QRCode_responseImgByValue",
    method: "POST",
    url: "/frontend/QRCode/responseImgByValue",
  },

  // 根据code 输出二维码图片流
  // doc: http://192.168.2.18:3000/project/779/interface/api/138105
  {
    funcName: "QRCode_responseImgByCode",
    method: "POST",
    url: "/frontend/QRCode/responseImgByCode",
  },

  // 扫码后code获取内容
  // doc: http://192.168.2.18:3000/project/779/interface/api/138109
  {
    funcName: "QRCode_getQRCodeDataByCode",
    method: "GET",
    url: "/frontend/QRCode/getQRCodeDataByCode",
  },

  // 记录扫码日志
  // doc: http://192.168.2.18:3000/project/779/interface/api/138113
  {
    funcName: "QRCode_recordQRCodeScanLogByValue",
    method: "POST",
    url: "/frontend/QRCode/recordQRCodeScanLogByValue",
  },

  // 根据code 输出二维码图片base64
  // doc: http://192.168.2.18:3000/project/779/interface/api/138117
  {
    funcName: "QRCode_responseImgBase64ByCode",
    method: "POST",
    url: "/frontend/QRCode/responseImgBase64ByCode",
  },

  // 根据内容 响应输出二维码图片base64
  // doc: http://192.168.2.18:3000/project/779/interface/api/138121
  {
    funcName: "QRCode_responseImgBase64ByValue",
    method: "POST",
    url: "/frontend/QRCode/responseImgBase64ByValue",
  },

  /**
   * ==================================================
   *  餐饮
   *  餐饮
   * ==================================================
   */

  // 餐饮列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138125
  {
    funcName: "Restaurant_query",
    method: "GET",
    url: "/frontend/Restaurant/query",
  },

  // 餐饮获取详情
  // doc: http://192.168.2.18:3000/project/779/interface/api/138129
  {
    funcName: "Restaurant_read",
    method: "GET",
    url: "/frontend/Restaurant/read",
  },

  // 餐饮发布评论
  // doc: http://192.168.2.18:3000/project/779/interface/api/138133
  {
    funcName: "Restaurant_saveComment",
    method: "POST",
    url: "/frontend/Restaurant/saveComment",
  },

  // 餐饮评论列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138137
  {
    funcName: "Restaurant_queryComment",
    method: "GET",
    url: "/frontend/Restaurant/queryComment",
  },

  // 餐饮评论删除
  // doc: http://192.168.2.18:3000/project/779/interface/api/138141
  {
    funcName: "Restaurant_deleteComment",
    method: "POST",
    url: "/frontend/Restaurant/deleteComment",
  },

  // 餐饮足迹保存
  // doc: http://192.168.2.18:3000/project/779/interface/api/138145
  {
    funcName: "Restaurant_saveVisit",
    method: "POST",
    url: "/frontend/Restaurant/saveVisit",
  },

  // 餐饮足迹列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138149
  {
    funcName: "Restaurant_queryVisit",
    method: "GET",
    url: "/frontend/Restaurant/queryVisit",
  },

  // 餐饮收藏
  // doc: http://192.168.2.18:3000/project/779/interface/api/138153
  {
    funcName: "Restaurant_saveCollection",
    method: "POST",
    url: "/frontend/Restaurant/saveCollection",
  },

  // 餐饮取消收藏
  // doc: http://192.168.2.18:3000/project/779/interface/api/138157
  {
    funcName: "Restaurant_deleteCollection",
    method: "POST",
    url: "/frontend/Restaurant/deleteCollection",
  },

  // 餐饮点赞
  // doc: http://192.168.2.18:3000/project/779/interface/api/138161
  {
    funcName: "Restaurant_saveLike",
    method: "POST",
    url: "/frontend/Restaurant/saveLike",
  },

  // 餐饮取消点赞
  // doc: http://192.168.2.18:3000/project/779/interface/api/138165
  {
    funcName: "Restaurant_deleteLike",
    method: "POST",
    url: "/frontend/Restaurant/deleteLike",
  },

  // 餐饮点赞列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138169
  {
    funcName: "Restaurant_queryLike",
    method: "GET",
    url: "/frontend/Restaurant/queryLike",
  },

  // 餐饮分享
  // doc: http://192.168.2.18:3000/project/779/interface/api/138173
  {
    funcName: "Restaurant_saveShare",
    method: "POST",
    url: "/frontend/Restaurant/saveShare",
  },

  // 浏览餐饮积分获取接口
  // doc: http://192.168.2.18:3000/project/779/interface/api/138177
  {
    funcName: "Restaurant_readCreditRecord",
    method: "POST",
    url: "/frontend/Restaurant/readCreditRecord",
  },

  /**
   * ==================================================
   *  餐馆分类
   *  餐馆分类
   * ==================================================
   */

  // 餐馆分类列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138181
  {
    funcName: "RestaurantCategory_query",
    method: "GET",
    url: "/frontend/RestaurantCategory/query",
  },

  // 餐馆分类所有列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138185
  {
    funcName: "RestaurantCategory_all",
    method: "GET",
    url: "/frontend/RestaurantCategory/all",
  },

  /**
   * ==================================================
   *  餐饮菜品
   *  餐饮菜品
   * ==================================================
   */

  // 餐饮菜品列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138189
  {
    funcName: "RestaurantDish_query",
    method: "GET",
    url: "/frontend/RestaurantDish/query",
  },

  // 所有餐饮菜品
  // doc: http://192.168.2.18:3000/project/779/interface/api/138193
  {
    funcName: "RestaurantDish_all",
    method: "GET",
    url: "/frontend/RestaurantDish/all",
  },

  // 餐饮菜品获取详情
  // doc: http://192.168.2.18:3000/project/779/interface/api/138197
  {
    funcName: "RestaurantDish_read",
    method: "GET",
    url: "/frontend/RestaurantDish/read",
  },

  // 餐饮菜品发布评论
  // doc: http://192.168.2.18:3000/project/779/interface/api/138201
  {
    funcName: "RestaurantDish_saveComment",
    method: "POST",
    url: "/frontend/RestaurantDish/saveComment",
  },

  // 餐饮菜品评论列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138205
  {
    funcName: "RestaurantDish_queryComment",
    method: "GET",
    url: "/frontend/RestaurantDish/queryComment",
  },

  // 餐饮菜品评论删除
  // doc: http://192.168.2.18:3000/project/779/interface/api/138209
  {
    funcName: "RestaurantDish_deleteComment",
    method: "POST",
    url: "/frontend/RestaurantDish/deleteComment",
  },

  // 餐饮菜品收藏
  // doc: http://192.168.2.18:3000/project/779/interface/api/138213
  {
    funcName: "RestaurantDish_saveCollection",
    method: "POST",
    url: "/frontend/RestaurantDish/saveCollection",
  },

  // 餐饮菜品取消收藏
  // doc: http://192.168.2.18:3000/project/779/interface/api/138217
  {
    funcName: "RestaurantDish_deleteCollection",
    method: "POST",
    url: "/frontend/RestaurantDish/deleteCollection",
  },

  // 餐饮菜品点赞
  // doc: http://192.168.2.18:3000/project/779/interface/api/138221
  {
    funcName: "RestaurantDish_saveLike",
    method: "POST",
    url: "/frontend/RestaurantDish/saveLike",
  },

  // 餐饮菜品取消点赞
  // doc: http://192.168.2.18:3000/project/779/interface/api/138225
  {
    funcName: "RestaurantDish_deleteLike",
    method: "POST",
    url: "/frontend/RestaurantDish/deleteLike",
  },

  /**
   * ==================================================
   *  景区
   *  景区
   * ==================================================
   */

  // 景区列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138229
  {
    funcName: "Scenic_query",
    method: "GET",
    url: "/frontend/Scenic/query",
  },

  // 所有景区
  // doc: http://192.168.2.18:3000/project/779/interface/api/138233
  {
    funcName: "Scenic_all",
    method: "GET",
    url: "/frontend/Scenic/all",
  },

  // 景区获取详情
  // doc: http://192.168.2.18:3000/project/779/interface/api/138237
  {
    funcName: "Scenic_read",
    method: "GET",
    url: "/frontend/Scenic/read",
  },

  // 景区发布评论
  // doc: http://192.168.2.18:3000/project/779/interface/api/138241
  {
    funcName: "Scenic_saveComment",
    method: "POST",
    url: "/frontend/Scenic/saveComment",
  },

  // 景区评论列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138245
  {
    funcName: "Scenic_queryComment",
    method: "GET",
    url: "/frontend/Scenic/queryComment",
  },

  // 景区评论删除
  // doc: http://192.168.2.18:3000/project/779/interface/api/138249
  {
    funcName: "Scenic_deleteComment",
    method: "POST",
    url: "/frontend/Scenic/deleteComment",
  },

  // 景区足迹保存
  // doc: http://192.168.2.18:3000/project/779/interface/api/138253
  {
    funcName: "Scenic_saveVisit",
    method: "POST",
    url: "/frontend/Scenic/saveVisit",
  },

  // 景区足迹列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138257
  {
    funcName: "Scenic_queryVisit",
    method: "GET",
    url: "/frontend/Scenic/queryVisit",
  },

  // 景区收藏
  // doc: http://192.168.2.18:3000/project/779/interface/api/138261
  {
    funcName: "Scenic_saveCollection",
    method: "POST",
    url: "/frontend/Scenic/saveCollection",
  },

  // 景区取消收藏
  // doc: http://192.168.2.18:3000/project/779/interface/api/138265
  {
    funcName: "Scenic_deleteCollection",
    method: "POST",
    url: "/frontend/Scenic/deleteCollection",
  },

  // 景区点赞
  // doc: http://192.168.2.18:3000/project/779/interface/api/138269
  {
    funcName: "Scenic_saveLike",
    method: "POST",
    url: "/frontend/Scenic/saveLike",
  },

  // 景区取消点赞
  // doc: http://192.168.2.18:3000/project/779/interface/api/138273
  {
    funcName: "Scenic_deleteLike",
    method: "POST",
    url: "/frontend/Scenic/deleteLike",
  },

  // 景区点赞列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138277
  {
    funcName: "Scenic_queryLike",
    method: "GET",
    url: "/frontend/Scenic/queryLike",
  },

  // 景区分享
  // doc: http://192.168.2.18:3000/project/779/interface/api/138281
  {
    funcName: "Scenic_saveShare",
    method: "POST",
    url: "/frontend/Scenic/saveShare",
  },

  // 阅读景区积分获取接口
  // doc: http://192.168.2.18:3000/project/779/interface/api/138285
  {
    funcName: "Scenic_readCreditRecord",
    method: "POST",
    url: "/frontend/Scenic/readCreditRecord",
  },

  // 景区打卡
  // doc: http://192.168.2.18:3000/project/779/interface/api/138289
  {
    funcName: "Scenic_savePunch",
    method: "POST",
    url: "/frontend/Scenic/savePunch",
  },

  // 查询用户在景区最后一次打卡记录
  // doc: http://192.168.2.18:3000/project/779/interface/api/138293
  {
    funcName: "Scenic_readMyLastPunch",
    method: "GET",
    url: "/frontend/Scenic/readMyLastPunch",
  },

  // 景区统计总数
  // doc: http://192.168.2.18:3000/project/779/interface/api/138297
  {
    funcName: "Scenic_statCount",
    method: "GET",
    url: "/frontend/Scenic/statCount",
  },

  // 景区打卡统计总数
  // doc: http://192.168.2.18:3000/project/779/interface/api/138301
  {
    funcName: "Scenic_statPunchCount",
    method: "GET",
    url: "/frontend/Scenic/statPunchCount",
  },

  /**
   * ==================================================
   *  景区分类
   *  景区分类
   * ==================================================
   */

  // 景区分类列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138305
  {
    funcName: "ScenicCategory_query",
    method: "GET",
    url: "/frontend/ScenicCategory/query",
  },

  // 景区分类获取详情
  // doc: http://192.168.2.18:3000/project/779/interface/api/138309
  {
    funcName: "ScenicCategory_read",
    method: "GET",
    url: "/frontend/ScenicCategory/read",
  },

  // 景区所有分类
  // doc: http://192.168.2.18:3000/project/779/interface/api/138313
  {
    funcName: "ScenicCategory_all",
    method: "GET",
    url: "/frontend/ScenicCategory/all",
  },

  /**
   * ==================================================
   *  景区设施关联
   *  景区设施关联
   * ==================================================
   */

  // 景区设施关联列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138317
  {
    funcName: "ScenicFacility_queryFacility",
    method: "GET",
    url: "/frontend/ScenicFacility/queryFacility",
  },

  // 景区设施关联所有数据
  // doc: http://192.168.2.18:3000/project/779/interface/api/138321
  {
    funcName: "ScenicFacility_allFacility",
    method: "GET",
    url: "/frontend/ScenicFacility/allFacility",
  },

  /**
   * ==================================================
   *  景区客流及停车场实时数据
   *  景区客流及停车场实时数据
   * ==================================================
   */

  // 景区客流及停车场实时数据
  // doc: http://192.168.2.18:3000/project/779/interface/api/138325
  {
    funcName: "ScenicFlow_query",
    method: "GET",
    url: "/frontend/ScenicFlow/query",
  },

  /**
   * ==================================================
   *  景区线路
   *  景区线路
   * ==================================================
   */

  // 景区线路列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138329
  {
    funcName: "ScenicLine_query",
    method: "GET",
    url: "/frontend/ScenicLine/query",
  },

  // 景区线路获取详情
  // doc: http://192.168.2.18:3000/project/779/interface/api/138333
  {
    funcName: "ScenicLine_read",
    method: "GET",
    url: "/frontend/ScenicLine/read",
  },

  // 景点导航
  // doc: http://192.168.2.18:3000/project/779/interface/api/138337
  {
    funcName: "ScenicLine_routeGuide",
    method: "GET",
    url: "/frontend/ScenicLine/routeGuide",
  },

  // 获取所有景区线路
  // doc: http://192.168.2.18:3000/project/779/interface/api/138341
  {
    funcName: "ScenicLine_all",
    method: "GET",
    url: "/frontend/ScenicLine/all",
  },

  /**
   * ==================================================
   *  景点
   *  景点
   * ==================================================
   */

  // 景点列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138345
  {
    funcName: "ScenicSpot_query",
    method: "GET",
    url: "/frontend/ScenicSpot/query",
  },

  // 所有景点
  // doc: http://192.168.2.18:3000/project/779/interface/api/138349
  {
    funcName: "ScenicSpot_all",
    method: "GET",
    url: "/frontend/ScenicSpot/all",
  },

  // 其他景点列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138353
  {
    funcName: "ScenicSpot_otherQuery",
    method: "GET",
    url: "/frontend/ScenicSpot/otherQuery",
  },

  // 其他所有景点
  // doc: http://192.168.2.18:3000/project/779/interface/api/138357
  {
    funcName: "ScenicSpot_otherAll",
    method: "GET",
    url: "/frontend/ScenicSpot/otherAll",
  },

  // 景点获取详情
  // doc: http://192.168.2.18:3000/project/779/interface/api/138361
  {
    funcName: "ScenicSpot_read",
    method: "GET",
    url: "/frontend/ScenicSpot/read",
  },

  // 景点发布评论
  // doc: http://192.168.2.18:3000/project/779/interface/api/138365
  {
    funcName: "ScenicSpot_saveComment",
    method: "POST",
    url: "/frontend/ScenicSpot/saveComment",
  },

  // 景点评论列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138369
  {
    funcName: "ScenicSpot_queryComment",
    method: "GET",
    url: "/frontend/ScenicSpot/queryComment",
  },

  // 景点评论删除
  // doc: http://192.168.2.18:3000/project/779/interface/api/138373
  {
    funcName: "ScenicSpot_deleteComment",
    method: "POST",
    url: "/frontend/ScenicSpot/deleteComment",
  },

  // 景点足迹保存
  // doc: http://192.168.2.18:3000/project/779/interface/api/138377
  {
    funcName: "ScenicSpot_saveVisit",
    method: "POST",
    url: "/frontend/ScenicSpot/saveVisit",
  },

  // 景点足迹列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138381
  {
    funcName: "ScenicSpot_queryVisit",
    method: "GET",
    url: "/frontend/ScenicSpot/queryVisit",
  },

  // 景点收藏
  // doc: http://192.168.2.18:3000/project/779/interface/api/138385
  {
    funcName: "ScenicSpot_saveCollection",
    method: "POST",
    url: "/frontend/ScenicSpot/saveCollection",
  },

  // 景点取消收藏
  // doc: http://192.168.2.18:3000/project/779/interface/api/138389
  {
    funcName: "ScenicSpot_deleteCollection",
    method: "POST",
    url: "/frontend/ScenicSpot/deleteCollection",
  },

  // 景点点赞
  // doc: http://192.168.2.18:3000/project/779/interface/api/138393
  {
    funcName: "ScenicSpot_saveLike",
    method: "POST",
    url: "/frontend/ScenicSpot/saveLike",
  },

  // 景点取消点赞
  // doc: http://192.168.2.18:3000/project/779/interface/api/138397
  {
    funcName: "ScenicSpot_deleteLike",
    method: "POST",
    url: "/frontend/ScenicSpot/deleteLike",
  },

  // 景点点赞列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138401
  {
    funcName: "ScenicSpot_queryLike",
    method: "GET",
    url: "/frontend/ScenicSpot/queryLike",
  },

  // 景点分享
  // doc: http://192.168.2.18:3000/project/779/interface/api/138405
  {
    funcName: "ScenicSpot_saveShare",
    method: "POST",
    url: "/frontend/ScenicSpot/saveShare",
  },

  // 阅读景点积分获取接口
  // doc: http://192.168.2.18:3000/project/779/interface/api/138409
  {
    funcName: "ScenicSpot_readCreditRecord",
    method: "POST",
    url: "/frontend/ScenicSpot/readCreditRecord",
  },

  // 景点打卡
  // doc: http://192.168.2.18:3000/project/779/interface/api/138413
  {
    funcName: "ScenicSpot_savePunch",
    method: "POST",
    url: "/frontend/ScenicSpot/savePunch",
  },

  // 查询用户在景点最后一次打卡记录
  // doc: http://192.168.2.18:3000/project/779/interface/api/138417
  {
    funcName: "ScenicSpot_readMyLastPunch",
    method: "GET",
    url: "/frontend/ScenicSpot/readMyLastPunch",
  },

  /**
   * ==================================================
   *  景点讲解资源
   *  景点讲解资源
   * ==================================================
   */

  // 景点讲解资源列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138421
  {
    funcName: "ScenicSpotVoice_query",
    method: "GET",
    url: "/frontend/ScenicSpotVoice/query",
  },

  // 景点讲解所有资源
  // doc: http://192.168.2.18:3000/project/779/interface/api/138425
  {
    funcName: "ScenicSpotVoice_all",
    method: "GET",
    url: "/frontend/ScenicSpotVoice/all",
  },

  // 景点讲解资源获取详情
  // doc: http://192.168.2.18:3000/project/779/interface/api/138429
  {
    funcName: "ScenicSpotVoice_read",
    method: "GET",
    url: "/frontend/ScenicSpotVoice/read",
  },

  // 景点讲解资源收藏
  // doc: http://192.168.2.18:3000/project/779/interface/api/138433
  {
    funcName: "ScenicSpotVoice_saveCollection",
    method: "POST",
    url: "/frontend/ScenicSpotVoice/saveCollection",
  },

  // 景点讲解资源取消收藏
  // doc: http://192.168.2.18:3000/project/779/interface/api/138437
  {
    funcName: "ScenicSpotVoice_deleteCollection",
    method: "POST",
    url: "/frontend/ScenicSpotVoice/deleteCollection",
  },

  // 景点讲解资源点赞
  // doc: http://192.168.2.18:3000/project/779/interface/api/138441
  {
    funcName: "ScenicSpotVoice_saveLike",
    method: "POST",
    url: "/frontend/ScenicSpotVoice/saveLike",
  },

  // 景点讲解资源取消点赞
  // doc: http://192.168.2.18:3000/project/779/interface/api/138445
  {
    funcName: "ScenicSpotVoice_deleteLike",
    method: "POST",
    url: "/frontend/ScenicSpotVoice/deleteLike",
  },

  /**
   * ==================================================
   *  景区讲解资源
   *  景区讲解资源
   * ==================================================
   */

  // 景区讲解资源列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138449
  {
    funcName: "ScenicVoice_query",
    method: "GET",
    url: "/frontend/ScenicVoice/query",
  },

  // 景区讲解所有资源
  // doc: http://192.168.2.18:3000/project/779/interface/api/138453
  {
    funcName: "ScenicVoice_all",
    method: "GET",
    url: "/frontend/ScenicVoice/all",
  },

  // 景区讲解资源获取详情
  // doc: http://192.168.2.18:3000/project/779/interface/api/138457
  {
    funcName: "ScenicVoice_read",
    method: "GET",
    url: "/frontend/ScenicVoice/read",
  },

  /**
   * ==================================================
   *  商店
   *  商店
   * ==================================================
   */

  // 商店列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138461
  {
    funcName: "Shop_query",
    method: "GET",
    url: "/frontend/Shop/query",
  },

  // 商店获取详情
  // doc: http://192.168.2.18:3000/project/779/interface/api/138465
  {
    funcName: "Shop_read",
    method: "GET",
    url: "/frontend/Shop/read",
  },

  // 商店发布评论
  // doc: http://192.168.2.18:3000/project/779/interface/api/138469
  {
    funcName: "Shop_saveComment",
    method: "POST",
    url: "/frontend/Shop/saveComment",
  },

  // 商店评论列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138473
  {
    funcName: "Shop_queryComment",
    method: "GET",
    url: "/frontend/Shop/queryComment",
  },

  // 商店评论删除
  // doc: http://192.168.2.18:3000/project/779/interface/api/138477
  {
    funcName: "Shop_deleteComment",
    method: "POST",
    url: "/frontend/Shop/deleteComment",
  },

  // 商店足迹保存
  // doc: http://192.168.2.18:3000/project/779/interface/api/138481
  {
    funcName: "Shop_saveVisit",
    method: "POST",
    url: "/frontend/Shop/saveVisit",
  },

  // 商店足迹列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138485
  {
    funcName: "Shop_queryVisit",
    method: "GET",
    url: "/frontend/Shop/queryVisit",
  },

  // 商店收藏
  // doc: http://192.168.2.18:3000/project/779/interface/api/138489
  {
    funcName: "Shop_saveCollection",
    method: "POST",
    url: "/frontend/Shop/saveCollection",
  },

  // 商店取消收藏
  // doc: http://192.168.2.18:3000/project/779/interface/api/138493
  {
    funcName: "Shop_deleteCollection",
    method: "POST",
    url: "/frontend/Shop/deleteCollection",
  },

  // 商店点赞
  // doc: http://192.168.2.18:3000/project/779/interface/api/138497
  {
    funcName: "Shop_saveLike",
    method: "POST",
    url: "/frontend/Shop/saveLike",
  },

  // 商店取消点赞
  // doc: http://192.168.2.18:3000/project/779/interface/api/138501
  {
    funcName: "Shop_deleteLike",
    method: "POST",
    url: "/frontend/Shop/deleteLike",
  },

  // 商店点赞列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138505
  {
    funcName: "Shop_queryLike",
    method: "GET",
    url: "/frontend/Shop/queryLike",
  },

  // 阅读商店积分获取接口
  // doc: http://192.168.2.18:3000/project/779/interface/api/138509
  {
    funcName: "Shop_readCreditRecord",
    method: "POST",
    url: "/frontend/Shop/readCreditRecord",
  },

  // 商店打卡
  // doc: http://192.168.2.18:3000/project/779/interface/api/138513
  {
    funcName: "Shop_savePunch",
    method: "POST",
    url: "/frontend/Shop/savePunch",
  },

  // 查询用户在商店最后一次打卡记录
  // doc: http://192.168.2.18:3000/project/779/interface/api/138517
  {
    funcName: "Shop_readMyLastPunch",
    method: "GET",
    url: "/frontend/Shop/readMyLastPunch",
  },

  /**
   * ==================================================
   *  商店分类
   *  商店分类
   * ==================================================
   */

  // 商店分类列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138521
  {
    funcName: "ShopCategory_query",
    method: "GET",
    url: "/frontend/ShopCategory/query",
  },

  // 商店分类所有列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138525
  {
    funcName: "ShopCategory_all",
    method: "GET",
    url: "/frontend/ShopCategory/all",
  },

  /**
   * ==================================================
   *  客户端-第三方账户登录
   *  客户端-第三方账户登录
   * ==================================================
   */

  // 获取账户绑定配置
  // doc: http://192.168.2.18:3000/project/779/interface/api/138529
  {
    funcName: "SocialAccount_getBindingConfig",
    method: "GET",
    url: "/frontend/SocialAccount/getBindingConfig",
  },

  // 通用-三方账户绑定已有账户接口
  // doc: http://192.168.2.18:3000/project/779/interface/api/138533
  {
    funcName: "SocialAccount_bindAccount",
    method: "POST",
    url: "/frontend/SocialAccount/bindAccount",
  },

  // 解除微信小程序账户绑定接口
  // doc: http://192.168.2.18:3000/project/779/interface/api/138537
  {
    funcName: "SocialAccount_unBindWxProgram",
    method: "POST",
    url: "/frontend/SocialAccount/unBindWxProgram",
  },

  // 获取第三方用户信息
  // doc: http://192.168.2.18:3000/project/779/interface/api/138541
  {
    funcName: "SocialAccount_getAccountInfo",
    method: "GET",
    url: "/frontend/SocialAccount/getAccountInfo",
  },

  /**
   * ==================================================
   *  客户端-第三方账户登录
   *  客户端-第三方账户登录
   * ==================================================
   */

  // 微信小程序登录接口
  // doc: http://192.168.2.18:3000/project/779/interface/api/138545
  {
    funcName: "SocialLogin_wxProgramLogin",
    method: "POST",
    url: "/frontend/SocialLogin/wxProgramLogin",
  },

  // 通用-三方账户注册接口
  // doc: http://192.168.2.18:3000/project/779/interface/api/138549
  {
    funcName: "SocialLogin_register",
    method: "POST",
    url: "/frontend/SocialLogin/register",
  },

  // 通用-三方账户注册接口 单一客户端
  // doc: http://192.168.2.18:3000/project/779/interface/api/138553
  {
    funcName: "SocialLogin_registerOP",
    method: "POST",
    url: "/frontend/SocialLogin/registerOP",
  },

  // 微信-三方账户注册接口 微信版本2.3.1 - 2.10.4
  // doc: http://192.168.2.18:3000/project/779/interface/api/138557
  {
    funcName: "SocialLogin_wxProgramRegister_2_3_1",
    method: "POST",
    url: "/frontend/SocialLogin/wxProgramRegister/2_3_1",
  },

  // 浙里办登录接口（不含微信小程序）
  // doc: http://192.168.2.18:3000/project/779/interface/api/138561
  {
    funcName: "SocialLogin_zjzwfwPuserLogin",
    method: "POST",
    url: "/frontend/SocialLogin/zjzwfwPuserLogin",
  },

  // 浙里办统一登录接口
  // doc: http://192.168.2.18:3000/project/779/interface/api/138565
  {
    funcName: "SocialLogin_zlbUserSsoLogin",
    method: "POST",
    url: "/frontend/SocialLogin/zlbUserSsoLogin",
  },

  /**
   * ==================================================
   *  字典库
   *  字典库
   * ==================================================
   */

  // 字典获取所有
  // doc: http://192.168.2.18:3000/project/779/interface/api/138569
  {
    funcName: "SystemDictionary_getDictionaryAll",
    method: "GET",
    url: "/frontend/SystemDictionary/getDictionaryAll",
  },

  /**
   * ==================================================
   *  标签
   *  标签
   * ==================================================
   */

  // 指定类型下的标签
  // doc: http://192.168.2.18:3000/project/779/interface/api/138573
  {
    funcName: "Tag_getTagsByType",
    method: "GET",
    url: "/frontend/Tag/getTagsByType",
  },

  /**
   * ==================================================
   *  旅游攻略
   *  旅游攻略
   * ==================================================
   */

  // 旅游攻略列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138577
  {
    funcName: "TravelGuide_query",
    method: "GET",
    url: "/frontend/TravelGuide/query",
  },

  // 用户的旅游攻略列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138581
  {
    funcName: "TravelGuide_myQuery",
    method: "GET",
    url: "/frontend/TravelGuide/myQuery",
  },

  // 新增旅游攻略
  // doc: http://192.168.2.18:3000/project/779/interface/api/138585
  {
    funcName: "TravelGuide_save",
    method: "POST",
    url: "/frontend/TravelGuide/save",
  },

  // 获取旅游攻略详情
  // doc: http://192.168.2.18:3000/project/779/interface/api/138589
  {
    funcName: "TravelGuide_read",
    method: "GET",
    url: "/frontend/TravelGuide/read",
  },

  // 获取我的旅游攻略详情
  // doc: http://192.168.2.18:3000/project/779/interface/api/138593
  {
    funcName: "TravelGuide_myRead",
    method: "GET",
    url: "/frontend/TravelGuide/myRead",
  },

  // 更新旅游攻略
  // doc: http://192.168.2.18:3000/project/779/interface/api/138597
  {
    funcName: "TravelGuide_update",
    method: "POST",
    url: "/frontend/TravelGuide/update",
  },

  // 删除旅游攻略
  // doc: http://192.168.2.18:3000/project/779/interface/api/138601
  {
    funcName: "TravelGuide_delete",
    method: "POST",
    url: "/frontend/TravelGuide/delete",
  },

  // 旅游攻略发布评论
  // doc: http://192.168.2.18:3000/project/779/interface/api/138605
  {
    funcName: "TravelGuide_saveComment",
    method: "POST",
    url: "/frontend/TravelGuide/saveComment",
  },

  // 旅游攻略评论列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138609
  {
    funcName: "TravelGuide_queryComment",
    method: "GET",
    url: "/frontend/TravelGuide/queryComment",
  },

  // 旅游攻略评论删除
  // doc: http://192.168.2.18:3000/project/779/interface/api/138613
  {
    funcName: "TravelGuide_deleteComment",
    method: "POST",
    url: "/frontend/TravelGuide/deleteComment",
  },

  // 旅游攻略足迹保存
  // doc: http://192.168.2.18:3000/project/779/interface/api/138617
  {
    funcName: "TravelGuide_saveVisit",
    method: "POST",
    url: "/frontend/TravelGuide/saveVisit",
  },

  // 旅游攻略足迹列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138621
  {
    funcName: "TravelGuide_queryVisit",
    method: "GET",
    url: "/frontend/TravelGuide/queryVisit",
  },

  // 旅游攻略收藏
  // doc: http://192.168.2.18:3000/project/779/interface/api/138625
  {
    funcName: "TravelGuide_saveCollection",
    method: "POST",
    url: "/frontend/TravelGuide/saveCollection",
  },

  // 旅游攻略取消收藏
  // doc: http://192.168.2.18:3000/project/779/interface/api/138629
  {
    funcName: "TravelGuide_deleteCollection",
    method: "POST",
    url: "/frontend/TravelGuide/deleteCollection",
  },

  // 旅游攻略点赞
  // doc: http://192.168.2.18:3000/project/779/interface/api/138633
  {
    funcName: "TravelGuide_saveLike",
    method: "POST",
    url: "/frontend/TravelGuide/saveLike",
  },

  // 旅游攻略取消点赞
  // doc: http://192.168.2.18:3000/project/779/interface/api/138637
  {
    funcName: "TravelGuide_deleteLike",
    method: "POST",
    url: "/frontend/TravelGuide/deleteLike",
  },

  // 旅游攻略点赞列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138641
  {
    funcName: "TravelGuide_queryLike",
    method: "GET",
    url: "/frontend/TravelGuide/queryLike",
  },

  // 旅游攻略分享
  // doc: http://192.168.2.18:3000/project/779/interface/api/138645
  {
    funcName: "TravelGuide_saveShare",
    method: "POST",
    url: "/frontend/TravelGuide/saveShare",
  },

  // 浏览旅游攻略积分获取接口
  // doc: http://192.168.2.18:3000/project/779/interface/api/138649
  {
    funcName: "TravelGuide_readCreditRecord",
    method: "POST",
    url: "/frontend/TravelGuide/readCreditRecord",
  },

  // 旅游攻略提交审批
  // doc: http://192.168.2.18:3000/project/779/interface/api/138653
  {
    funcName: "TravelGuide_commitAudit",
    method: "POST",
    url: "/frontend/TravelGuide/commitAudit",
  },

  // 撤回指定旅游攻略提交审批
  // doc: http://192.168.2.18:3000/project/779/interface/api/138657
  {
    funcName: "TravelGuide_revokeCommitAudit",
    method: "POST",
    url: "/frontend/TravelGuide/revokeCommitAudit",
  },

  // 旅游攻略获取审批结果记录
  // doc: http://192.168.2.18:3000/project/779/interface/api/138661
  {
    funcName: "TravelGuide_queryAuditLogs",
    method: "GET",
    url: "/frontend/TravelGuide/queryAuditLogs",
  },

  /**
   * ==================================================
   *  视频
   *  视频
   * ==================================================
   */

  // 视频列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138709
  {
    funcName: "Video_query",
    method: "GET",
    url: "/frontend/Video/query",
  },

  // 视频获取详情
  // doc: http://192.168.2.18:3000/project/779/interface/api/138713
  {
    funcName: "Video_read",
    method: "GET",
    url: "/frontend/Video/read",
  },

  /**
   * ==================================================
   *  用户足迹
   *  用户足迹
   * ==================================================
   */

  // 我的用户足迹列表
  // doc: http://192.168.2.18:3000/project/779/interface/api/138717
  {
    funcName: "Visit_myQuery",
    method: "GET",
    url: "/frontend/Visit/myQuery",
  },

  // 我的用户足迹总数
  // doc: http://192.168.2.18:3000/project/779/interface/api/138721
  {
    funcName: "Visit_myNumber",
    method: "GET",
    url: "/frontend/Visit/myNumber",
  },

  /**
   * ==================================================
   *  天气
   *  天气
   * ==================================================
   */

  // 实时天气
  // doc: http://192.168.2.18:3000/project/779/interface/api/138725
  {
    funcName: "Weather_nowWeather",
    method: "GET",
    url: "/frontend/Weather/nowWeather",
  },

  // 和风天气
  // doc: http://192.168.2.18:3000/project/779/interface/api/138729
  {
    funcName: "Weather_qWeather",
    method: "GET",
    url: "/frontend/Weather/qWeather",
  },

  // 和风天气3天天气预报
  // doc: http://192.168.2.18:3000/project/779/interface/api/138733
  {
    funcName: "Weather_weather3d",
    method: "GET",
    url: "/frontend/Weather/weather3d",
  },

  // 和风天气7天天气预报
  // doc: http://192.168.2.18:3000/project/779/interface/api/138737
  {
    funcName: "Weather_weather7d",
    method: "GET",
    url: "/frontend/Weather/weather7d",
  },

  // 和风天气逐小时预报（未来24小时）
  // doc: http://192.168.2.18:3000/project/779/interface/api/138741
  {
    funcName: "Weather_weather24h",
    method: "GET",
    url: "/frontend/Weather/weather24h",
  },

  /**
   * ==================================================
   *  微信JSSDK
   *  微信JSSDK
   * ==================================================
   */

  // 微信JSSDK获取注入权限验证配置
  // doc: http://192.168.2.18:3000/project/779/interface/api/138745
  {
    funcName: "WechatJSSDK_config",
    method: "POST",
    url: "/frontend/WechatJSSDK/config",
  },
  {
    funcName: "Goods_query",
    method: "Get",
    url: "/frontend/Goods/query",
  },
  {
    funcName: "Goods_read",
    method: "Get",
    url: "/frontend/Goods/read",
  },
  {
    funcName: "Shop_query",
    method: "Get",
    url: "/frontend/Shop/query",
  },
  {
    funcName: "Shop_read",
    method: "Get",
    url: "/frontend/Shop/read",
  },
];
