const commonConfig = {
  // 请求地址
  BASE_API: "/api",
  // BASE_API: "http://shop.yby.qxslyfjq.top",
  // BASE_API: "localhost:8080/api",
  // BASE_API: "http://192.168.2.192:8081/api",
  SHOP_API: "",
  // 请求签名
  SIGN_KEY: "yskey.key",
};

export default {
  ...commonConfig,
};
