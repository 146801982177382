import http from "@/utils/axios/axios.js";
import apiConfig from "./apiConfig";
import apiConfigTp5 from "./apiConfigTP5.js";
import config from "@/config";

apiConfigTp5.map((item) => (item.url = config.SHOP_API + item.url));
const contactApi = apiConfig.concat(apiConfigTp5);
const modulesFuncGather = {};
contactApi.forEach(({ funcName, url, method }) => {
  /**
   * @{params} data 普通参数
   * @{params} options 局部参数，覆盖之前的设定
   * @{params} files 触发文件上传
   */
  modulesFuncGather[funcName] = function (data, options = {}) {
    method = method.toLowerCase();
    const axiosConfig = {
      method,
      url,
      ...options,
    };
    if (data instanceof FormData) {
      axiosConfig.data = data;
      axiosConfig.headers = {
        "content-type": "multipart/form-data",
      };
    } else if (method === "get") {
      axiosConfig.params = data;
    } else {
      axiosConfig.data = data;
    }
    return http(axiosConfig);
  };
});

export default modulesFuncGather;
