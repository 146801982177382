import $store from "@/store";
/**
 * 一级菜单的en作为二级菜单的对象名，以此类推
 * hasLeaf: true用于提示有下级菜单，以及route的赋值
 * hide: true, //不在菜单中显示
 */
const Menus = [
  {
    zh: "网站首页",
    en: "Home",
    path: "home",
  },
  {
    zh: "小镇介绍",
    en: "Introduction",
    hasLeaf: true,
  },
  {
    zh: "小镇资讯",
    en: "Information",
    hasLeaf: true,
  },
  {
    zh: "周边推荐",
    en: "Surroundings",
    hasLeaf: true,
  },
  {
    zh: "小镇地图",
    en: "Map",
    path: "scenicGuided",
  },
  {
    zh: "小镇服务",
    en: "Service",
    hasLeaf: true,
  },
  // {
  //   zh: "交通指南",
  //   en: "Traffic",
  //   hasLeaf: true,
  // },
  // {
  //   zh: "旅游保障",
  //   en: "Guarantee",
  //   hasLeaf: true,
  // },
];

const SecondMenus = [
  {
    zh: "小镇介绍",
    en: "Introduction",
    path: "overview",
    headImg: "overview",
    // headTitle: {
    //   zh: "畅游茗山，从这里开始",
    //   en: "Visit the Expo Garden, start here",
    // },
    parent: "Introduction",
  },
  {
    zh: "小镇导览",
    en: "Scenic Spot",
    path: "scenic",
    hasLeaf: true,
    headImg: "overview",
    // headTitle: {
    //   zh: "逛茗山，赏全球多彩风光",
    //   en: "Visit Nanning Garden Expo and enjoy the colorful scenery of the world",
    // },
    parent: "Introduction",
  },
  {
    zh: "游览路线",
    en: "Scenic Line",
    path: "scenic-guide",
    hasLeaf: true,
    headImg: "overview",
    // headTitle: {
    //   zh: "逛茗山，赏全球多彩风光",
    //   en: "Visit Nanning Garden Expo and enjoy the colorful scenery of the world",
    // },
    parent: "Introduction",
  },
  // {
  //   zh: "景区公告",
  //   en: "Announcement",
  //   path: "announcement",
  //   type: "jqgg",
  //   hasLeaf: true,
  //   headImg: "announcement",
  //   headTitle: {
  //     zh: "南宁园博园，想告诉你的秘密",
  //     en: "Nanning Garden Expo, I want to tell you the secret",
  //   },
  //   parent: "News",
  // },
  {
    zh: "小镇资讯",
    en: "News",
    path: "news",
    type: "jqxw",
    hasLeaf: true,
    headImg: "activity",
    // headTitle: {
    //   zh: "南宁园博园，热点在身边",
    //   en: "Nanning Garden Expo, hot spot around",
    // },
    parent: "Information",
  },
  {
    zh: "商品推荐",
    en: "Goods",
    path: "shop",
    hasLeaf: true,
    headImg: "shop",
    headTitle: {
      zh: "游南宁园博园，购南宁特色礼品",
      en: "Visit Nanning Garden Expo and buy Nanning special gifts",
    },
    parent: "Surroundings",
  },
  {
    zh: "美食推荐",
    en: "Delicious Food",
    path: "repast",
    type: "tsms",
    hasLeaf: true,
    headImg: "shop",
    headTitle: {
      zh: "游在园博园，吃在南宁市",
      en: "Visit the Garden Expo and eat in Nanning",
    },
    parent: "Surroundings",
  },
  {
    zh: "娱乐推荐",
    en: "Entertainment",
    path: "entertainment",
    type: "xxyl",
    hasLeaf: true,
    headImg: "shop",
    headTitle: {
      zh: "游在园博园，玩在南宁市",
      en: "Visit the Expo Garden, play in Nanning City",
    },
    parent: "Surroundings",
  },
  {
    zh: "住宿推荐",
    en: "Stay",
    path: "accommodation",
    type: "zstj",
    hasLeaf: true,
    headImg: "shop",
    headTitle: {
      zh: "南宁园博园，时刻为你服务",
      en: "Nanning Garden Expo is always at your service",
    },
    parent: "Surroundings",
  },
  {
    zh: "虚拟旅游",
    en: "Virtual",
    path: "virtual",
    hasLeaf: false,
    hide: true,
    parent: "Raiders",
  },
  {
    zh: "地图导览",
    en: "Guided",
    path: "scenicGuided",
    hasLeaf: false,
    hide: true,
    headImg: "share",
    headTitle: {
      zh: "南宁园博园，在你的地图世界中",
      en: "Nanning Garden Expo, in your map world",
    },
    parent: "Raiders",
  },
  {
    zh: "咨询服务",
    en: "Contact",
    path: "contact",
    headImg: "contact",
    // headTitle: {
    //   zh: "南宁园博园，时刻为你服务",
    //   en: "Nanning Garden Expo is always at your service",
    // },
    parent: "Service",
  },
  {
    zh: "公共服务",
    en: "server",
    path: "server",
    headImg: "contact",
    hasLeaf: true,
    // headTitle: {
    //   zh: "你关心的事情，都在这里为你解答",
    //   en: "Everything you care about is answered here for you",
    // },
    parent: "Service",
  },
  // {
  //   zh: "在线投诉",
  //   en: "Complaint",
  //   path: "complaint",
  //   parent: "Serve",
  // },
  // {
  //   zh: "游客建议",
  //   en: "Suggestion",
  //   path: "suggest",
  //   parent: "Serve",
  // },
  // {
  //   zh: "天气查询",
  //   en: "Weather",
  //   path: "weather",
  //   parent: "Serve",
  // },
  // {
  //   zh: "交通指南",
  //   en: "Bus",
  //   path: "traffic",
  //   type: "bus",
  //   headImg: "",
  //   headTitle: {
  //     zh: "南宁园博园，欢迎世界各地的朋友",
  //     en: "Nanning Garden Expo, welcomes friends from all over the world",
  //   },
  //   parent: "Serve",
  // },
];
const ThirdMenus = [
  {
    zh: "景点详情",
    en: "Scenic Spot Detail",
    path: "scenicDetail",
    parent: "Scenic Spot",
  },
  {
    zh: "植物详情",
    en: "Plant Detail",
    path: "plantDetail",
    parent: "Plant",
  },
  {
    zh: "热门活动详情",
    en: "Activity Detail",
    path: "activity-detail",
    parent: "Activity",
  },
  {
    zh: "景区公告详情",
    en: "Announcement Detail",
    path: "announcement-detail",
    parent: "Announcement",
  },
  {
    zh: "小镇资讯详情",
    en: "News Detail",
    path: "news-detail",
    parent: "News",
  },
  {
    zh: "标准化创建专栏详情",
    en: "Standardized Creation Detail",
    path: "creation-detail",
    parent: "Standardized Creation",
  },
  {
    zh: "攻略详情",
    en: "Raiders Detail",
    path: "raiders-detail",
    parent: "Raiders",
  },
  {
    zh: "美食详情",
    en: "Foods Detail",
    path: "food-detail",
    parent: "Food",
  },
  {
    zh: "餐饮详情",
    en: "Repast Detail",
    path: "RepastDetail",
    parent: "Delicious Food",
  },
  {
    zh: "娱乐详情",
    en: "Entertainment Detail",
    path: "EntertainmentDetail",
    parent: "Entertainment",
  },
  {
    zh: "住宿详情",
    en: "Stay Detail",
    path: "AccommodationDetail",
    parent: "Stay",
  },
  {
    zh: "商品详情",
    en: "Goods Detail",
    path: "shopDetail",
    parent: "Goods",
  },
  {
    zh: "科普详情",
    en: "Knowledge Detail",
    path: "knowledge-detail",
    parent: "Knowledge",
  },
  {
    zh: "重点详情",
    en: "Knowledge Detail",
    path: "server-detail",
    parent: "server",
  },
  {
    zh: "线路详情",
    en: "Line Detail",
    path: "guide-detail",
    parent: "Scenic Line",
  },
];

const FooterMenus = [
  {
    zh: "小镇介绍",
    en: "Introduction",
    secondMenu: [
      {
        zh: "小镇概况",
        en: "Overview",
        path: "overview",
        level: 2,
      },
      {
        zh: "小镇导览",
        en: "Guide",
        path: "scenic",
        level: 2,
      },
      {
        zh: "游览路线",
        en: "Line",
        path: "scenic-guide",
        level: 2,
      },
    ],
  },
  {
    zh: "小镇资讯",
    en: "Information",
    secondMenu: [
      {
        zh: "小镇资讯",
        en: "Information",
        path: "news",
        level: 2,
      },
    ],
  },
  {
    zh: "周边推荐",
    en: "Surroundings",
    secondMenu: [
      {
        zh: "住宿推荐",
        en: "Stay",
        path: "accommodation",
        level: 2,
      },
      {
        zh: "美食推荐",
        en: "Delicious Food",
        path: "repast",
        level: 2,
      },
      {
        zh: "娱乐推荐",
        en: "Entertainment",
        path: "entertainment",
        level: 2,
      },
      {
        zh: "商品推荐",
        en: "Goods",
        path: "shop",
        level: 2,
      },
    ],
  },
  {
    zh: "720全景",
    en: "720 Panorama",
    secondMenu: [
      {
        zh: "全景导览",
        en: "720 Panorama",
        path: "https://f96mc704coi.720yun.com/vr/3a0jOssvta7",
        level: 2,
      },
    ],
  },
  {
    zh: "小镇地图",
    en: "Map",
    secondMenu: [
      {
        zh: "小镇地图",
        en: "Map",
        path: "scenicGuided",
        level: 2,
      },
    ],
  },
  {
    zh: "小镇服务",
    en: "Service",
    secondMenu: [
      {
        zh: "咨询服务",
        en: "Service",
        path: "contact",
        level: 2,
      },
      {
        zh: "公共服务",
        en: "Service",
        path: "server",
        level: 2,
      },
    ],
  },
];

const searchMenuSetting = [
  {
    level: 3, //查询进入的目标页层级 首页1 列表页2 详情页3
    type: "spot", //接口数据类别
    path: "scenicDetail",
  },
  {
    level: 2,
    type: "pic",
    path: "scenicPicture",
  },
  {
    level: 2,
    type: "video",
    path: "scenicVedio",
  },
  {
    level: 3,
    type: "plant",
    path: "plantDetail",
  },
  {
    level: 3,
    type: "rmhd",
    path: "activity-detail",
  },
  {
    level: 3,
    type: "jqgg",
    path: "announcement-detail",
  },
  {
    level: 2,
    type: "bus",
    path: "traffic",
  },
  {
    level: 2,
    type: "railway",
    path: "traffic",
  },
  {
    level: 2,
    type: "flight",
    path: "traffic",
  },
  {
    level: 2,
    type: "drive",
    path: "traffic",
  },
  {
    level: 3,
    type: "lygl",
    path: "raiders-detail",
  },
  {
    level: 3,
    type: "tsms",
    path: "food-detail",
  },
  {
    level: 3,
    type: "xxyl",
    path: "raidersDetailMap",
  },
  {
    level: 3,
    type: "zstj",
    path: "raidersDetailMap",
  },
  {
    level: 1,
    type: "mpydxz",
    path: "ticket",
  },
  {
    level: 1,
    type: "lxwm",
    path: "contact",
  },
  {
    level: 1,
    type: "ybygk",
    path: "overview",
  },
];

// const GuidedMenuBar = {
//   scenic_spot:{
//     zh:"景点",
//     en:"Scenic spot",
//   },
//   clinic:{
//     zh:"医务室",
//     en:"Clinic",
//   },
//   gate:{
//     zh:"出入口",
//     en:"Gate",
//   },
//   parking:{
//     zh:"停车场",
//     en:"Parking",
//   },
//   toilet:{
//     zh:"厕所",
//     en:"Toilet",
//   }
// }

function getSettingLang() {
  return $store.getters.getLang;
}

function getHeaderMenus() {
  const settingLang = getSettingLang();

  let menu = Menus.map((first, f) => {
    const headerMenuIndex = localStorage.getItem("headerMenuIndex");
    first.isActive = headerMenuIndex ? f == headerMenuIndex : false; //菜单选中状态
    first.title = first[settingLang]; //菜单展示名
    if (first.headTitle) {
      first.headTitle.title = first.headTitle[settingLang]; //副标题
    }
    first.routeIndex = f + ""; //索引路径
    let second = null;
    let third = null;
    //二级菜单
    if (first.hasLeaf) {
      second = [];
      for (let i in SecondMenus) {
        let s = SecondMenus[i];
        if (s.parent == first.en) {
          //一级菜单部分无路径
          s.route = first.path || "" + "," + s.path;
          s.routeIndex = f + "," + i;
          s.title = s[settingLang];
          s.headTitle ? (s.headTitle.title = s.headTitle[settingLang]) : 0;
          second.push(s);
          //三级菜单
          if (s.hasLeaf) {
            third = [];
            for (let j in ThirdMenus) {
              let t = ThirdMenus[j];
              if (t.parent == s.en) {
                t.route = first.path || "" + "," + s.path + "," + t.path;
                t.routeIndex = f + "," + i + "," + j;
                t.title = t[settingLang];
                third.push(t);
              }
            }
            s.thirdMenus = third;
          }
        }
      }
      first.secondMenus = second;
    }
    return first;
  });
  return menu;
}

function getFooterMenus() {
  const settingLang = getSettingLang();
  let menuData = {};
  let secList = [];
  let menuList = FooterMenus.map((menu) => {
    menuData = {
      ...menu,
      title: menu[settingLang],
    };
    secList = menu.secondMenu.map((m) => {
      return {
        ...m,
        title: m[settingLang],
      };
    });
    menuData.secList = secList;
    return menuData;
  });
  return menuList;
}

// function getDetailMenus() {
//   const settingLang = getSettingLang()
//   let detailMenus = DetailMenus.map(dMenu => {
//     return {
//       ...dMenu,
//       title: dMenu[settingLang]
//     }
//   })
//   return detailMenus
// }

// function getGuidedMenuBar(){
//   const settingLang = getSettingLang()
//   let menu = []
//   for(let i in GuidedMenuBar){
//     menu.push({
//       title:GuidedMenuBar[i][settingLang],
//       type:i
//     })
//   }
//   return menu
// }

function getMenusSetting() {
  return {
    FirstFloorMenu: Menus,
    SecondFloorMenu: SecondMenus,
    ThirdFloorMenu: ThirdMenus,
    HeaderMenus: getHeaderMenus(),
    FooterMenus: getFooterMenus(),
    SearchMenuSetting: searchMenuSetting,
  };
}

export { getMenusSetting };
