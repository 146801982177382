import { createStore } from "vuex";
import { getAllSetting } from "@/utils/setting.js";
import { getMenusSetting } from "@/utils/settingMenus.js";
import { getAllContextSetting } from "@/utils/settingContext.js";
import { getAllTicketSetting } from "@/utils/settingTicket.js";
export default createStore({
  state: {
    lang: "zh",
    menus: {},
    new_menus: {},
    langSetting: {},
    contextSetting: {},
    ticketSetting: {},
    bread: [],
    pageSetting: {
      //页面上的配置
      zh: "",
      en: "",
      title: "",
    },
  },
  getters: {
    getLang(state) {
      return state.lang;
    },
    //页面信息获取，主要是页头名称
    // getPageSetting(state) {
    //   if(!state.pageSetting || JSON.stringify(state.pageSetting) === '{}'){
    //     let pageSetting = JSON.parse(sessionStorage.getItem('pageSetting'))
    //     if(pageSetting){
    //       return pageSetting
    //     }
    //   }
    //   return state.pageSetting
    // },
  },
  mutations: {
    changeLang(state, lang) {
      if (lang) {
        this.state.lang = lang;
        sessionStorage.setItem("lang", lang);
        // sessionStorage.setItem('lang',lang);
        // console.log(sessionStorage.getItem('lang'));
        // sessionStorage.setItem('lang',JSON.stringify(lang));
        // JSON.parse(sessionStorage.getItem('lang'))
      }
    },
    setLangSetting(state) {
      this.state.langSetting = getAllSetting();
      this.state.contextSetting = getAllContextSetting();
      this.state.ticketSetting = getAllTicketSetting();
      let menus = getMenusSetting();
      this.state.menus = menus;
      //初始化时从缓存拿历史选中菜单
      const index = localStorage.getItem("headerMenuIndex") || 0;
      this.state.menus.HeaderMenus = menus.HeaderMenus.map((m, i) => {
        m.isActive = i == index;
        return m;
      });
      // this.state.pageSetting.title = this.state.pageSetting[state.lang]
      // console.log(getMenusSetting())
    },
    changeLangBread(state) {
      let bread = this.state.bread;
      if (bread && bread.length > 0)
        this.state.bread = bread.map((b) => {
          b.breadcrumbName = b[this.state.lang];
          return b;
        });
    },
    changePageSetting(state, setting) {
      this.state.pageSetting = {
        ...this.state.pageSetting,
        ...setting,
        title: setting[state.lang],
      };
    },
    setMenu(state, menu) {
      this.state.menus.HeaderMenus = menu;
    },
    // addBread(state,menu){
    //   this.state.bread = [...state.bread,menu]
    // },
    setBread(state, bread) {
      this.state.bread = bread;
      sessionStorage.setItem("bread", JSON.stringify(bread));
    },
  },
  actions: {
    changeLang(store, type) {
      // console.log('changeLang')
      store.commit("changeLang", type);
      store.commit("setLangSetting");
      store.commit("changeLangBread");
    },
    routerChange(store, to) {
      const level = to.meta.level || 1;
      const path = to.name;
      const target = getMenu(level, path);
      const routeList = divideRoute(target.route);
      let home = store.state.menus.FirstFloorMenu.find((f) => {
        return f.path == "home";
      });
      home.breadcrumbName = home[store.state.lang]; //面包屑名称
      let breadList = [home];
      let bObject = null;
      routeList.map((route, i) => {
        bObject = getMenu(i + 1, route);
        if (route && bObject) {
          bObject.breadcrumbName = bObject[store.state.lang]; //面包屑名称
          breadList.push(bObject);
        }
      });
      target.breadcrumbName = target[store.state.lang]; //面包屑名称

      //放入目标路由项
      breadList.push(target);
      //放入面包屑
      store.commit("setBread", breadList);

      //头部菜单激活
      let routeIndex = target.routeIndex.split(",");
      localStorage.setItem("headerMenuIndex", routeIndex[0]); //放入缓存
      store.dispatch("updateMenu", routeIndex[0]);

      //分割路径，获取父级菜单
      function divideRoute(route) {
        if (!route) return [];
        let routeList_f = route.split(",");
        //除了最后一项，前置项都需要查询出来
        routeList_f.pop();
        return routeList_f;
      }

      function getMenu(level, t) {
        if (!t) return null;
        let menuList_f = null;
        let target_f = null;
        switch (level) {
          case 1:
            menuList_f = store.state.menus.FirstFloorMenu;
            break;
          case 2:
            menuList_f = store.state.menus.SecondFloorMenu;
            break;
          case 3:
            menuList_f = store.state.menus.ThirdFloorMenu;
            break;
        }
        for (let i in menuList_f) {
          if (menuList_f[i].path == t) {
            target_f = menuList_f[i];
            break;
          }
        }
        return target_f;
      }
    },
    //更新菜单
    updateMenu(store, index) {
      let menu = store.state.menus.HeaderMenus;
      let new_menu = menu.map((m, i) => {
        m.isActive = i == index;
        return m;
      });
      localStorage.setItem("headerMenuIndex", index);
      store.commit("setMenu", new_menu);
    },
  },
  modules: {},
});
