import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import http from "@/api";
import Antd from 'ant-design-vue';
import './global.less' // global style
import './global_animated.less' // global animate
import wow from 'wowjs'
import 'animate.css'
import './assets/styles/reset.less'

const app = createApp(App);


app.use(store);
app.use(router);
app.use(Antd);

// app.provide('$http', http);
app.config.globalProperties.$http = http;
app.config.globalProperties.$store = store;
app.config.globalProperties.$wow = wow;

app.mount('#app');
