<template>
  <a-config-provider :locale="locale">
    <div id="app" class="appPage">
      <Header />
      <div class="page-inside">
        <router-view v-if="isRouterAlive" v-slot="{ Component }">
          <transition name="fade">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
      <Footer />
      <div class="back-top">
        <a-back-top>
          <div class="ant-back-top-inner">
            <VerticalAlignTopOutlined />
            <div class="text">{{top}}</div>
          </div>
        </a-back-top>
      </div>
    </div>
  </a-config-provider>
</template>

<script>
import {
  VerticalAlignTopOutlined
} from '@ant-design/icons-vue';
import { mapState } from "vuex";
import Header from "@/components/page-header/header";
import Footer from "@/components/page-footer/footer";
export default {
  components: {
    VerticalAlignTopOutlined,
    Header,
    Footer,
  },
  name: "App",
  //允许一个祖先组件向其所有子孙后代注入一个依赖，不论组件层次有多深，并在起上下游关系成立的时间里始终生效
  //用于页面语言切换造成的数据更新
  provide(){
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true,
      locale: null,
    }
  },
  computed:{
    ...mapState({
      settingLang: state => state.lang,
      bread: (state) => state.bread,
    }),
    top(){
      return this.settingLang == 'zh'?'顶部':'UP'
    },
  },
  watch:{
    settingLang:{
      handler(val){
        this.setLanguage(val || "zh")
      },
      immediate: true
    }
  },
  created(){
    //初始化语言设置  //每次刷新都会触发 需要更改默认初始化方式
    let lang_storage = sessionStorage.getItem("lang")
    this.$store.dispatch("changeLang",lang_storage || "zh")
    //从session中取面包屑，防止刷新消失
    if(!(this.bread&&this.bread.length>0)){
      let bread = JSON.parse(sessionStorage.getItem('bread')) || []
      this.$store.commit('setBread', bread);
    }
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(() => (this.isRouterAlive = true))
    },
    setLanguage(lang) {
      //插件自带的
      switch (lang) {
        case "zh":
          this.locale = require("ant-design-vue/es/locale-provider/zh_CN").default;
          break;
        case "en":
          this.locale = require("ant-design-vue/es/locale-provider/en_US").default;
          break;
        default:
          this.locale = require("ant-design-vue/es/locale-provider/en_US").default;
          break;
      }
      console.log(lang,this.locale)
    }
  }
};
</script>

<style lang="less">
body{
  width: 100%;
//   scrollbar-width: none; /* firefox */
//   -ms-overflow-style: none; /* IE 10+ */
//   &::-webkit-scrollbar {
//     display: none; /* Chrome Safari */
  // }

  overflow-x: hidden;
  font-family:Microsoft YaHei;
}
.page-inside{
  // padding-top: 160px;
}
.appPage{
  width: 100vw;
}
.back-top .ant-back-top{
  right: 115px;
  transform: scale(1.75);
  bottom: 74px;
  background: rgba(27, 27, 27, 0.5);
  border-radius: 2px;
  .ant-back-top-inner{
    height: 40px;
    width: 40px;
    line-height: 40px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .text{
      font-size: 12px;
      line-height: 12px;
      transform: scale(0.6);
    }
  }
}
</style>
