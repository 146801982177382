import axios from "axios";
import globalConfig from "@/config/index";
// import dayjs from "dayjs";
import md5 from "blueimp-md5";
import { message as Message } from "ant-design-vue";
import $store from "@/store";

const instance = axios.create({
  baseURL: globalConfig.BASE_API,
  timeout: 150000,
  headers: {
    platform: "Website",
    // platform: globalConfig.PLATFORM,
    // appid: globalConfig.APPID,
    // time: dayjs(new Date()).unix() + "",
  },
});

// request拦截器
instance.interceptors.request.use((config) => {
  // 区分是否是上传
  if (!(config.data instanceof FormData)) {
    config.data = {
      ...filterObjectNullVal(config["data"]),
    };
  }
  config.params = config.params || {};
  //时间戳
  config.params.time = Date.parse(new Date());
  //语言
  config.params.lang = $store.getters.getLang;

  // 生成签名
  const sign = generateSign({
    ...config.data,
    ...config["params"],
    ...parseURL(config.url),
  });
  config.headers.sign = sign;
  config.params.sign = sign;
  config.headers.Authorization = "";
  // console.log(config.params)
  return config;
});

// respone拦截器
instance.interceptors.response.use(
  (response) => {
    const data = response.data;

    // 服务端返回的状态码不等于200，则reject()
    if (response.status !== 200) {
      return Promise.reject(response);
    }
    if (typeof response.data === "string") {
      response.data = JSON.parse(response.data);
    }
    // 如果接口错误码不为0，则进行错误处理
    const isInvalidResult = data.code !== 200;
    console.log(isInvalidResult);
    if (isInvalidResult) {
      apiErrorCodeHandling(response);
      return Promise.reject(response);
    }
    return data.result;
  },
  (error) => {
    console.log(error);
    httpCodeHandling(error.response);
    return Promise.reject(error.response);
  }
);

/**
 * 生成sign
 * @param {object} params get的query参数， post的query、body参数
 */
function generateSign(params) {
  // 签名的key
  const signKey = globalConfig.SIGN_KEY;
  let keyArrSort = Object.keys(params).sort();
  let sign = "";
  keyArrSort.map((key) => {
    let val = params[key];
    if (
      val !== "" &&
      val !== null &&
      val !== undefined &&
      typeof val !== "object" &&
      typeof val !== "boolean"
    ) {
      sign += `${key}=${val}&`;
    }
  });
  if (sign.substr(-1) == "&") {
    sign = sign.substring(0, sign.length - 1);
  }
  sign = sign + signKey;
  return md5(sign);
}

/**
 * 筛选得到非null字段
 * @param {object} params 对象
 */
function filterObjectNullVal(params) {
  const result = {};
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const isNullVal = params[key] === null;
      if (!isNullVal) {
        result[key] = params[key];
      }
    }
  }
  return result;
}

// 解析url?后面的参数
function parseURL(url) {
  let theRequest = new Object();
  if (url.indexOf("?") != -1) {
    let str = url.split("?")[1];
    let strs = str.split("&");
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
    }
  }
  return theRequest;
}

/**
 * 错误返回码 统一处理
 */
function apiErrorCodeHandling(errorObj) {
  // 错误返回码
  let error_code = Number(errorObj.data.code);
  let message = errorObj.data.message || "";
  switch (error_code) {
    // 系统繁忙
    case 401:
      message = `${message}`;
      break;
    // token过期
    case 402:
      // message = `ERROR_CODE:${error_code}::TOKEN_EXPIRE`
      message = `登录已失效，请重新登录`;
      break;

    default:
      message = `ERROR_CODE:${error_code}::UNKNOWN ERROR`;
      break;
  }
  Message.error(message);
}

/**
 * HTTP 状态码 错误处理
 */
function httpCodeHandling(response) {
  // const code = response.data.code;
  const code = response.status;
  let message = response.data.message || "";
  switch (code) {
    case 401:
      console.log(message);
      break;
    case 402:
      // message = `ERROR_CODE:${error_code}::TOKEN_EXPIRE`
      message = `登录已失效，请重新登录`;
      break;
    case 404:
      Message.error("接口不存在");
      break;
    case 408:
      Message.error("服务器繁忙,请稍后再试");
      break;
    case 500:
      Message.error("服务器繁忙,请稍后再试");
      break;
    default:
      Message.error(`UNHANDLED_HTTP_CODE:${code}`);
      break;
  }
}

export default instance;
