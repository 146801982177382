import $store from "@/store";
const Setting = {
	complaint: {
		introduceTitle: {
			zh: "在线投诉介绍",
			en: "Complaint Introduce"
		},
		introduceOnce: {
			zh: "投诉人相关信息如果不属实或无法与投诉人取得联系，投诉很可能按无效投诉不予处理。",
			en: "If the relevant information of the complainant is not true or cannot be contacted with the complainant, the complaint is likely to not be handled as an invalid complaint."
		},
		introduceTwo: {
			zh: "投诉人应当对投诉内容的真实性负责，因虚假投诉引起的法律责任，由投诉人承担。",
			en: "The complainant shall be responsible for the authenticity of the content of the complaint, and the legal liability caused by the false complaint shall be borne by the complainant."
		},
		introduceThree: {
			zh: "一般投诉问题景区将在5个工作日给予答复，复杂些的适当延长。",
			en: "General complaints will be answered within 5 working days, and the more complicated ones will be extended appropriately."
		},
		introduceFour: {
			zh: "不属于本景区受理范围的问题视为无效问题，本栏目管理人员有权对此删改并不再告知。",
			en: "Issues that are not within the scope of acceptance of this scenic spot shall be regarded as invalid issues, and the management personnel of this column have the right to delete and modify them and not notify them again."
		},
		title: {
			zh: '在线投诉',
			en: 'Complaint'
		},
		place:{
			zh: '事发地',
			en: 'Place'
			// en: 'Place of incident'
		},
		placePlaceholder: {
			zh: '请输入事发地',
			en: 'Please type the location of the incident'
		},
		name: {
			zh: '姓名',
			en: 'Name'
		},
		namePlaceholder: {
			zh: '请输入姓名',
			en: 'Please type in your name'
		},
		phone: {
			zh: '联系方式',
			en: 'Phone'
		},
		phonePlaceholder: {
			zh: '请输入联系电话',
			en: 'Please type your phone number'
		},
		respondent: {
			zh: '投诉对象',
			en: 'Respondent'
		},
		respondentPlaceholder: {
			zh: '请输入投诉对象',
			en: 'Please type respondent'
		},
		content: {
			zh: '投诉内容',
			en: 'Content'
		},
		contentPlaceholder: {
			zh: '请输入投诉的详细内容',
			en: 'Please type content'
		},
		vCode:{
			zh: '验证码',
			en: 'Vcode'
			// en: 'Verification code'
		},
		vCodePlaceholder:{
			zh: '请输入验证码',
			en: 'Please type verification code'
		},
		submitButton: {
			zh: '确认并提交',
			en: 'Submit'
		}
	},
	suggest: {
		introduceTitle: {
			zh: "游客建议介绍",
			en: "Suggestion Introduce"
		},
		introduceOnce: {
			zh: "园博园景区欢迎广大游客提出宝贵意见和建议，完善景区旅游服务，一同创造更美丽的旅游环境。",
			en: "Garden Expo Park scenic spot welcomes the vast number of visitors to put forward valuable opinions and suggestions, improve the scenic tourism services, together to create a more beautiful tourism environment."
		},
		introduceTwo: {
			zh: "游客提出的建议是对园博园景区工作的关心和支持，景区将正确对待并改善。",
			en: "The suggestions put forward by the tourists are the concern and support for the work of the garden Expo park scenic spot, and the scenic spot will treat and improve it correctly."
		},
	  title: {
	    zh: '游客建议',
	    en: 'Suggest'
	  },
	  type: {
	    zh: '建议类型',
	    en: 'Type'
	  },
	  typePlaceholder: {
	    zh: '请选择建议类型',
	    en: 'Please enter the suggested type'
	  },
		typeSlect1:{
			zh: '服务建议',
			en: 'Service suggestions'
		},
		typeSlect2:{
			zh: '功能建议',
			en: 'Function suggestion'
		},
		typeSlect3:{
			zh: '其他',
			en: 'Others'
		},
	  name: {
	    zh: '姓名',
	    en: 'Name'
	  },
	  namePlaceholder: {
	    zh: '请输入姓名',
	    en: 'Please type in your name'
	  },
	  phone: {
	    zh: '手机号码',
	    en: 'Phone'
	  },
	  phonePlaceholder: {
	    zh: '请输入联系电话',
	    en: 'Please type your phone number'
	  },
	  content: {
	    zh: '建议内容',
	    en: 'Content'
	  },
	  contentPlaceholder: {
	    zh: '请输入建议内容',
	    en: 'Please type content'
	  },
		vCode:{
			zh: '验证码',
			en: 'Vcode'
			// en: 'Verification code'
		},
		vCodePlaceholder:{
			zh: '请输入验证码',
			en: 'Please type verification code'
		},
	  submitButton: {
	    zh: '确认并提交',
	    en: 'Submit'
	  }
	},
	contact: {
	  type: {
	    zh: '建议类型',
	    en: 'Type'
	  },
	  typePlaceholder: {
	    zh: '请选择建议类型',
	    en: 'Please enter the suggested type'
	  },
		title: {
		  zh: "南宁园博园",
		  en: "Nanning Garden Expo",
		},
		consult: {
		  zh: "旅游咨询",
		  en: "Tourism consultation",
		},
		complaint: {
		  zh: "旅游投诉",
		  en: "Tourism complaints",
		},
		address:{
			zh: "地址",
			en: "Address",
		},
		ticket: {
			zh: "售票热线",
			en: "Ticket Hotline",
		},
		rescue: {
			zh: "救助电话",
			en: "Rescue call",
		},
		business: {
			zh: "商务合作热线",
			en: "Business Cooperation Hotline",
		},
		addressDetail:{
			zh: "南宁邕宁区蒲兴大道20号",
			en: "No. 20, Puxing Avenue, Yongning District, Nanning",
		},
		phonesTitle:{
			zh: "旅游相关电话",
			en: "Travel related telephone",
		},
		phonesList:[
			{
				zh: "南宁市文旅局咨询电话：0771-5655551",
				en: "Consultation telephone of Nanning culture and Tourism Bureau : 0771-565555",
			},
			{
				zh: "南宁吴圩机场咨询电话：0771-96365",
				en: "Nanning Wuwei airport Hotline : 0771-96365",
			},
			{
				zh: "铁路咨询电话：95306",
				en: "Railway consultation telephone : 95306",
			},
			{
				zh: "的士咨询电话：95128",
				en: "Taxi Enquiry Hotline : 95128",
			},
		],
	},
	traffic: {
		transit: {
			zh:[
				{
				title: "地铁+公交",
				list: [
					"乘坐地铁3号线至五象湖站（A出口、B出口）换乘B02路快速公交。",
					"乘坐地铁2号线至金象站（A出口）换乘B02路快速公交。",
					"乘坐地铁1号线至埌东客运站（B出口）换乘旅游2路公交（周末、节假日运营）。"
				]},
				{
				title: "公交路线",
				list: [
					"园博园东大门（正常开放）：B02路、703路、旅游2路（周末、节假日开通）、123路(节假日运营）园博园站下车。",
				]},
			]
			,
			en:[
				{
					title: "Subway+public transportation",
					list: [
						"Take subway line 3 to Wuxianghu Station (exits A and B) and transfer to the B02 rapid bus.",
						"Take Subway Line 2 to Jinxiang Station (Exit A) and transfer to B02 Rapid Transit.",
						"Take Metro Line 1 to Langdong Bus Station (Exit B) and transfer to Tourism Bus No. 2 (operated on weekends and holidays)."
					]
				},{
					title: "Common transit route",
					list: [
						"Yuanbo Garden East Gate (normally open): B02 Road, 703 Road, Tourism 2 Road (open on weekends and holidays), 123 Road",
					]
				}
			]
		},
		aircraft: {
			zh:[
				{
				title: "乘飞机导览南宁吴圩机场",
				list: [
					"直达航班：南宁—广州、南宁—深圳、南宁—厦门、南宁—珠海、南宁—桂林等。",
				]},
				{
				title: "公交路线",
				list: [
					"机场巴士2号线转轨道交通2号线转B20路、",
					"机场巴士2号线转99/41路转B20路、",
					"机场巴士1号线转轨道交通2号线转B20路、",
					"机场巴士4号线转42区间转B20路"
				]},
			]
			,
			en:[
				{
					title: "Take a self driving route to Nanning Wuwei airport by plane",
					list: [
						"Direct flights: Nanning Guangzhou, Nanning Shenzhen, Nanning Xiamen, Nanning Zhuhai, Nanning Guilin, etc.",
					]
				},{
					title: "Common transit route",
					list: [
						"Airport bus line 2 to rail transit line 2 to B20",
						"Airport bus line 2 to 99 / 41 to B20",
						"Airport bus line 1 to rail transit line 2 to B20",
						"Airport bus line 4 to section 42 to B20",
					]
				}
			]
		},
		train: {
			zh:[
				{
				title: "乘火车到达南宁站：",
				list: [
					"21路转B02路。",
					"轨道交通1号线转2号线转B02路。",
					"704路转703路",
					"K87路转703路",
				]},
				{
				title: "乘火车到达宁南东站：",
				list: [
					"轨道交通1号线转3号线转B02路：南宁火车东站北—园博园 ",
					"K209路转703路",
					"轨道交通1号线转42路区间转B20路",
				]},
			]
			,
			en:[
				{
					title: "Take the train to Nanning station",
					list: [
						"Turn from No. 21 to No. B02.",
						"Rail Transit Line 1 to line 2 to road B02.",
						"704 road to 703 Road",
						"K87 road to 703 Road",
					]
				},{
					title: "Take the train to Ningnan east railway station",
					list: [
						"Rail Transit Line 1 to line 3 to B02 Road: North of Nanning East Railway Station - yuanboyuan",
						"K209 road to 703 Road",
						"Rail Transit Line 1 turns to No. 42 road and section turns to No. B20 road",
					]
				}
			]
		},
		drive: {
			zh:[
				{
				title: "",
				list: [
					"导航南宁园博园（南宁市邕宁区蒲兴大道20号）",
				]},
			]
			,
			en:[
				{
					title: "",
					list: [
						"Navigation Expo Park (No. 20, Puxing Avenue, Yongning District, Nanning)",
					]
				},
			]
		},
		scenic:{
			zh:[{
				title:'停车场收费标准：',
				standard:['小型车：30分钟内免费停放；前2小时内：5元；之后每1小时：2元；',
					'中型车：30分钟内免费停放；前2小时内：6元；之后每1小时：2元；',
					'大型车：30分钟内免费停放；前2小时内：8元；之后每1小时：2元；',
					'特大型车：30分钟内免费停放；前2小时内：10元；之后每1小时：2元； 具有新能源汽车识别标识的新能源汽车停放，减半收费。',
					'持有《中华人民共和国残疾人证》残疾人驾驶的已合法注册登记的残疾人专用车辆， 2个小时以内免费停放。前2小时内不足2小时按2小时计，2小时后不足1小时按1小时计。'
				]
			},{
				title:'电动代步车租车费用：',
				standard:['三座电动代步车：周一至周五优惠价40元/小时/辆，周末及节假日60元/小时/辆押金300元/辆。',
					'四座电动代步车：周一至周五优惠价60元/小时/辆，周末及节假日80元/小时/辆押金400元/辆',
					'租车须知：',
					'1.租赁电动代步车需在电动代步车租赁点现场根据工作人员指引使用小程序办理。',
					'2.电动代步车租赁业务需持个人有效C2及以上驾驶证办理证件，一个人的有效证件只能租赁一辆车。',
					'3.温馨提示:严禁未成年人、孕妇、酒后人员及其他不合适驾车的人员驾驶车辆。'
				]
			},{
				title:'观光车、小火车乘车费用：',
				standard:['单次票：5元/人/次，通票：30元/人/张(当天不限乘坐次数）',
					'',
					'1、观光车营运时间：每天9:00-18:00 每隔10——15分钟发一次车 ',
					'园博园观光车游览路线',
					'东门线观光车运营线路如下',
					'东大门站（始发站）→南门站→小马牧场站→清水泉站→紫薇花园站→芦草叠塘站→生态烧烤场站→北门站→玲珑岛站→中国东盟友谊馆站→东大门站（终点站）',
					'2、北门线观光车运营线路如下：',
					'北门站（始发站）→玲珑岛站→中国东盟友谊馆站→东大门站→南门站→小马牧场站→清水泉站→紫薇花园站→芦草叠塘站→生态烧烤场站→北门站（终点站）',
					'观光车包车费用：',
					'13座车：300元/小时，每超出1小时加收200元（不到半小时按照半小时算） ',
					'21座车：400元/小时，每超出1小时加收200元（不到半小时按照半小时算）',
					'包车预约热线：0771-6770051',
				]
			}],
			en:[{
				title:'Parking fee：',
				standard:['Small-sized cars: free parking within 30 minutes; within the first 2 hours: 5 yuan; every 1 hour thereafter: 2 yuan;',
					'Medium-sized cars: free parking within 30 minutes; within the first 2 hours: 6 yuan; every 1 hour thereafter: 2 yuan;',
					'Large-sized cars: free parking within 30 minutes; within the first 2 hours: 8 yuan; every 1 hour thereafter: 2 yuan;',
					'Extra-large cars: free parking within 30 minutes; within the first 2 hours: 10 yuan; every 1 hour thereafter: 2 yuan; parking for new energy vehicles with new energy vehicle identification marks, halving the charge.',
					`A legally registered special vehicle for the disabled that is driven by a disabled person with the "People's Republic of China Disability Certificate" can be parked for free within 2 hours. Less than 2 hours within the first 2 hours is counted as 2 hours, and less than 1 hour after 2 hours is counted as 1 hour.`
				]
			},{
				title:'Electric scooter rental fee：',
				standard:['Three-seater electric scooters: Monday to Friday discounted price 40 yuan/hour/vehicle, weekends and holidays 60 yuan/hour/vehicle deposit 300 yuan/vehicle.',
					'Four-seater electric scooter: a discounted price of 60 yuan/hour/vehicle from Monday to Friday, 80 yuan/hour/vehicle deposit 400 yuan/vehicle on weekends and holidays.',
					'Car rental notice:',
					'1. To rent an electric scooter, you need to use a small program on the spot at the electric scooter rental point according to the instructions of the staff.',
					`2. Electric scooter rental business requires a valid personal C2 and above driver's license to apply for a certificate, and a valid certificate for one person can only rent one car.`,
					'3. Warm reminder: It is strictly forbidden for minors, pregnant women, people after drinking and other people who are not suitable to drive to drive the vehicle.'
				]
			},{
				title:'Sightseeing car, small train travel expenses：',
				standard:['Single ticket: 5 yuan/person/time, pass ticket: 30 yuan/person/piece (unlimited rides on the day)',
					'',
					'1. Sightseeing bus operating hours: 9:00-18:00 every day, every 10-15 minutes',
					'Garden Expo Sightseeing Car Tour Route',
					'The operating routes of the Dongmen Line sightseeing bus are as follows:',
					'Dongdaemun Station (Departure Station) → Nanmen Station → Xiaoma Ranch Station → Qingshuiquan Station → Ziwei Garden Station → Lucao Dietang Station → Ecological Barbecue Station → Beimen Station → Linglong Island Station → China-ASEAN Friendship Hall Station →Dongdaemun Station (terminal station)',
					'2. The operating routes of the sightseeing bus on the Beimen Line are as follows:',
					'Beimen Station (Departure Station) → Linglong Island Station → China-ASEAN Friendship Hall Station → Dongdaemun Station → Nanmen Station → Xiaoma Ranch Station → Qingshuiquan Station → Ziwei Garden Station → Lucao Dietang Station → Ecological BBQ Station → Beimen Station (Terminal Station)',
					'Sightseeing car charter cost:',
					'13-seater car: 300 yuan/hour, 200 yuan will be charged for every extra hour (less than half an hour will be calculated as half an hour)',
					'21-seater car: 400 yuan/hour, 200 yuan will be charged for every extra hour (less than half an hour will be calculated as half an hour)',
					'Charter hotline: 0771-6770051',
				]
			}],
		}
	},
	search: {
		button: {
		  zh: '搜索',
		  en: 'Search'
		},
		placeholder: {
		  zh: '请输入搜索内容',
		  en: 'Please enter the search content'
		},
	},
}

function getSettingLang() {
	return $store.getters.getLang
}

function getComplaintSetting() {
	const settingLang = getSettingLang()
	let detailSetting = {}
	for (var key in Setting.complaint) {
		detailSetting[key] = Setting.complaint[key][settingLang]
	}
	return detailSetting
}

function getSuggestSetting() {
	const settingLang = getSettingLang()
	let detailSetting = {}
	for (var key in Setting.suggest) {
		detailSetting[key] = Setting.suggest[key][settingLang]
	}
	return detailSetting
}

function getContactSetting() {
	const settingLang = getSettingLang()
	let detailSetting = {}
	for (var key in Setting.contact) {
		if(Setting.contact[key].constructor === Array){
			let list = [];
			for(var i in Setting.contact[key]) {
				list.push(Setting.contact[key][i][settingLang])
			}
			detailSetting[key] = list;
		}else {
			detailSetting[key] = Setting.contact[key][settingLang]
		}
	}
	return detailSetting
}

function getTrafficSetting() {
	const settingLang = getSettingLang()
	let detailSetting = {}
	for (var key in Setting.traffic) {
		let objectSetting = [];
		objectSetting = Setting.traffic[key][settingLang]
		
		detailSetting[key] = objectSetting
	}
	return detailSetting
}

function getSearchSetting() {
	const settingLang = getSettingLang()
	let detailSetting = {}
	for (var key in Setting.search) {
		detailSetting[key] = Setting.search[key][settingLang]
	}
	return detailSetting
}

function getAllContextSetting() {
	return {
		ComplaintSetting: getComplaintSetting(),
		SuggestSetting: getSuggestSetting(),
		ContactSetting: getContactSetting(),
		TrafficSetting: getTrafficSetting(),
		SearchSetting:getSearchSetting(),
	}
}

export {
	getAllContextSetting,
};
