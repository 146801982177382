<template>
  <div
    class="header flex align-center justify-between animated_set_short"
    :style="{ background: config.background }"
  >
    <div class="flex align-center">
      <img
        :src="headLogo"
        class="header-logo hover-hand animated_set_short"
        :class="config.flag ? '' : 'logo-small'"
        @click="goHome"
      />
      <div class="flex flex-direction justify-center">
        <div class="flex align-center" style="padding-bottom: 15px">
          <img
            src="@/assets/icon/youke.png"
            style="width: 21px; height: 22px"
          />
          <div
            style="
              margin-left: 8px;
              font-size: 16px;
              line-height: 17px;
              color: #fff;
            "
          >
            最大日游客承载量
            <span
              class="text-right margin-left-xs"
              style="
                width: 60px;
                font-size: 20px;
                line-height: 19px;
                color: #95fc66;
              "
              >{{ nums[0] }}</span
            >
            人
          </div>
        </div>
        <div
          class="flex align-center margin-top-xs"
          style="padding: 12px 16rpx 12px 22px; color: #fff"
        >
          <img src="@/assets/icon/yk.png" style="width: 16px; height: 18px" />
          <div style="margin-left: 10px; font-size: 16px; line-height: 17px">
            最大瞬时游客承载量
            <span
              class="text-right margin-left-xs"
              style="
                width: 60px;
                font-size: 20px;
                line-height: 19px;
                color: #dac87f;
              "
              >{{ nums[1] }}</span
            >
            人
          </div>
        </div>
      </div>
    </div>
    <div class="menus-container flex flex-direction align-end">
      <div class="bottom flex align-center">
        <a-dropdown
          placement="bottomCenter"
          :overlayStyle="overlayStyle"
          v-for="(menus, index) in HeaderMenus"
          :key="index"
          :getPopupContainer="
            (triggerNode) => {
              return triggerNode.parentNode;
            }
          "
        >
          <div
            class="ant-dropdown-link hover-hand flex flex-direction"
            v-if="!menus.hide"
            @click="changeMenu(menus, index)"
          >
            <div class="flex align-center">
              <div
                class="menu-button"
                :class="menus.isActive ? 'menu-active' : ''"
              >
                {{ menus.title }}
              </div>
              <CaretDownOutlined
                v-if="menus.hasLeaf"
                class="animated_set icon-arrow"
              />
            </div>
            <div class="text-center" style="font-size: 14px">
              {{ menus.en }}
            </div>
          </div>

          <template #overlay>
            <a-menu v-if="menus.hasLeaf">
              <a-menu-item
                v-for="(menu, k) in menus.secondMenus"
                :key="k"
                @click="changeMenuItem(menu, index)"
              >
                <div style="padding: 4px 20px" v-if="!menu.hide">
                  {{ menu.title }}
                </div>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { SearchOutlined, CaretDownOutlined } from "@ant-design/icons-vue";
import { mapState } from "vuex";
export default {
  name: "Header",
  components: {
    SearchOutlined,
    CaretDownOutlined,
  },
  inject: ["reload"],
  data() {
    return {
      headLogo: "logo.png",
      config: {
        //样式切换
        background: "",
        flag: true,
      },
      // language: { index: 0, list: ["中文", "English"] }, //语言下拉选择
      overlayStyle: { padding: "18px 0 0 0" },
      nums: 0,
    };
  },
  created() {
    this.scrollListener();
    this.getNum();
  },
  computed: {
    ...mapState({
      settingLang: (state) => state.lang,
      HeaderSetting: (state) => state.langSetting.HeaderSetting,
      HeaderMenus: (state) => state.menus.HeaderMenus,
      bread: (state) => state.bread,
    }),
  },
  methods: {
    getNum() {
      this.$http.Index_getOpenSystemConfig().then((data) => {
        console.log(data);
        this.nums = [data.richengzailiang, data.shunshichengzailiang];
      });
    },
    //监听滚动条距离页面顶部的距离
    scrollListener() {
      let self = this;
      window.addEventListener("scroll", function (event) {
        let scrollTop =
          document.documentElement.scrollTop ||
          window.pageYOffset ||
          document.body.scrollTop;
        if (scrollTop > 200) {
          self.config = {
            flag: false,
            background: "rgba(27, 27, 27, 0.75)",
          };
        } else {
          self.config = {
            flag: true,
            background: "",
          };
        }
      });
    },
    //修改菜单选中状态
    changeMenu(menus, index) {
      console.log(menus);
      //没有子菜单
      if (!menus.menu && menus.path) {
        this.goPage(menus);
      }
    },
    //子菜单选中切换路由
    changeMenuItem(menu, index) {
      //交通指南是同一个页面，在这里互相区分,衔接锚点，与footer一致
      if (menu.path === "traffic") {
        // this.resetBread();
        localStorage.setItem("trafficType", menu.type);
        this.goAnchor(menu.type);
      }
      this.goPage(menu);
    },
    //移动到锚点
    goAnchor(type) {
      this.$nextTick(() => {
        let head = document.getElementsByClassName("header-bottom");
        let box = document.getElementsByClassName(type);
        if (box && box[0] && box[0].offsetTop) {
          // 获取需要滚动的距离
          let box_top = box[0].offsetTop;
          let head_height = head[0].offsetHeight;
          let move_height = box_top - head_height;
          // Chrome
          document.body.scrollTop = move_height;
          // Firefox
          document.documentElement.scrollTop = move_height;
          // Safari
          window.pageYOffset = move_height;
        } else {
          //在页面中放置初次加载的锚点移动
          console.log("交通指南页面初次加载，header取不到元素");
        }
      });
    },
    //路由跳转
    goPage(menu) {
      let urlParams = {
        name: menu.path,
        query: {},
      };
      if (menu.path) this.$router.push(urlParams);
    },
    //修改语言
    changeLang(event) {
      this.$store.dispatch("changeLang", event.key);
      //跳转首页
      this.goHome();
    },
    //返回主页
    goHome() {
      let target = "home";
      this.reload();
      this.goPage({ path: target });
    },
    //美图分享
    shareClick() {
      let target = "pictureShare";
      this.goPage({ path: target });
    },
    searchClick(path) {
      this.goPage({ path: path });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@theme-active-color: #22915b;
@color-base: #fff;
@color-back: rgba(27, 27, 27, 0.3);
.header {
  line-height: 1.5em;
  position: fixed;
  z-index: 10;
  width: 100%;
  background: @color-back;
  // height: 105px;
  padding: 0 240px;
}
.header-logo {
  padding: 1rem;
}
.logo-small {
  transform: scale(0.8);
  padding: 0.5rem 2.8rem 0.5rem 1rem;
}
.menus-container {
  height: 100%;
  color: @color-base;
  .top {
    height: 25px;
    margin-top: 15px;
    font-size: 0.3rem;

    .flex-set {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .share {
      padding: 0 1rem;
      border-right: 1px solid @color-base;
      display: flex;
      align-items: center;
    }

    .hotLine {
      padding-left: 0.8rem;
      border-right: 1px solid @color-base;
      display: flex;
      align-items: center;
    }

    .search {
      padding-left: 0.8rem;
    }
  }
  .bottom {
    height: 70px;
    font-size: 0.9rem;

    .menu-button {
      // height: 100%;
      margin: 0 10px;
      padding: 0.2rem 0.8rem;
      border-radius: 4px;
      font-size: 16px;
      &:hover {
        color: #fff;
        background: @theme-active-color;
      }
      &:hover .icon-arrow {
        transform: rotate(-180deg);
      }
      .icon-arrow {
        margin-left: 2px;
      }
    }
    .menu-active {
      color: #fff;
      background: @theme-active-color;
    }
  }
}

.ant-dropdown-menu {
  background: rgba(255, 255, 255, 0.75);
  /deep/ .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    color: @theme-active-color;
    background: transparent;
  }
  /deep/.ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-titles {
    padding: 0;
  }
}
</style>
